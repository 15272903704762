export default {
  top_right_grid: 'top_right_grid',
  top_right_grid_animate: 'top_right_grid_animate',
  top_right_grid_link: 'top_right_grid_link',
  middle_right_grid: 'middle_right_grid',
  middle_right_grid_animate: 'middle_right_grid_animate',
  middle_right_grid_link: 'middle_right_grid_link',
  bottom_right_grid: 'bottom_right_grid',
  bottom_right_grid_animate: 'bottom_right_grid_animate',
  bottom_right_grid_link: 'bottom_right_grid_link',
  bottom_middle_grid: 'bottom_middle_grid',
  bottom_middle_grid_animate: 'bottom_middle_grid_animate',
  bottom_middle_grid_link: 'bottom_middle_grid_link',
  bottom_left_grid: 'bottom_left_grid',
  bottom_left_grid_animate: 'bottom_left_grid_animate',
  bottom_left_grid_link: 'bottom_left_grid_link',
  featured_slider: 'featured_slider',
  content_block: 'content_block',
  strain_drops_title: 'strain_drops_title',
  strain_drops_content: 'strain_drops_content',
  strain_drops_products: 'strain_drops_products',
  faqs: 'faqs'
}
