import { ENUM_PLATFORM_ENDPOINT } from '@/utils/api/const'
import { ApiRequest } from '@/utils/api/api.request'
import { requestPipe } from '@/utils/api/api-pipes'

const config = {
  headers: {
    Authorization: 'Bearer 2|1CRoQlmMggvN74yDwi1YIXGDCo7jl5g3H5dgUqqX'
  }
}

export class ShopPlatformRequests extends ApiRequest {
  getProducts(q, location = null) {
    let query = '?'

    if (q) {
      query += q
      // console.log("query found", query);
      if (location) {
        query += '&'

        // console.log("query and location", query);
      }
    }

    if (location) {
      query += `location_id=${location}`
      // console.log("location", query);
    }

    const url = `${ENUM_PLATFORM_ENDPOINT.SHOP.GET_ALL_PRODUCTS}${query}`
    // console.log("get prod url", url);

    return this.get(url, config).then(requestPipe)
  }

  getBrands() {
    return this.get(`${ENUM_PLATFORM_ENDPOINT.SHOP.GET_ALL_BRANDS}`, config).then(requestPipe)
  }

  getSuppliers() {
    return this.get(`${ENUM_PLATFORM_ENDPOINT.SHOP.GET_ALL_SUPPLIERS}`, config).then(requestPipe)
  }

  getStrains() {
    return this.get(`${ENUM_PLATFORM_ENDPOINT.SHOP.GET_ALL_STRAINS}`, config).then(requestPipe)
  }

  getShopFiltersData() {
    return this.get(`${ENUM_PLATFORM_ENDPOINT.SHOP.GET_SHOP_FILTERS_DATA}`, config).then(requestPipe)
  }
}
