import env from '@/environment'

export const TokenInterceptor = (request) => {
  // These are for WC requests.
  if (request.url.includes('wp-json') && process.client) {
    const jwt = localStorage.getItem('token')

    if (jwt) {
      request.headers.Authorization = `Bearer ${jwt}`
      request.headers.Accept = 'application/json'
    }
  }

  // Add SALVE_KEY to headers.
  if (request.url.includes('wp-json') && process.server) {
    request.headers['Salve-Key'] = env.WC_TOKEN || 'TR031785NR032586JZ090790'
  }

  return request
}
