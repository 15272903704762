import QueryToURLParams from './QueryToURLParams'

/**
 * Converts a query object into a URL
 * Currently, if a query key is an array then its a list of selectable fields that can be passed as is once the
 * data has been url encoded. If a query key is an object, then its a range from min to max and should contain
 * two keys named min and max respectively. These values must be converted to url query variables with the
 * structure {{ url }}?min_{{ query key}}={{key.min}}&max_{{ query key }}={{ key.max }}
 *
 * Example:
 * url = www.cats.com
 * query = {
 *   price: {
 *     min: 10,
 *     max: 50,
 *   },
 *   categories: ["potato", "carrot"]
 * }
 *
 * the resulting URL will be
 * www.cats.com?min_price=10&max_price=50&categories[]=potato&categories[]=carrot
 *
 * which gets url encoded to this
 * www.cats.com?min_price=10&max_price=50&categories%5B%5D=potato&categories%5B%5D=carrot
 *
 * @param query
 * @constructor
 */
export default function MakeProductURL(url, query = {}) {
  return url + '?' + QueryToURLParams(query)
}
