import env from '@/environment'

export const ENUM_VERSION_API = {
  V1: 'v1'
}

const api = env.STORE_URL
const salve = env.PLATFORM_URL

export const ENUM_END_POINT = {
  AUTH: {
    SIGN_IN: `${api}/wp-json/jwt-auth/v1/token`,
    LOGOUT: 'logout',
    SIGN_UP: `${api}/wp-json/salve/v1/user/register`,
    SEND_RECOVER_CODE: 'send-recover-code',
    SEND_CONFIRM_EMAIL: 'confirm-email',
    RESET_PASSWORD: `${api}/wp-json/bdpwr/v1/reset-password`,
    RESET_PASSWORD_CONFIRM: `${api}/wp-json/bdpwr/v1/set-password`
  },
  BLOG: {
    GET_BLOG: `${api}/wp-json/wp/v2/pages/?slug=blog`,
    GET_ARTICLE: `${api}/wp-json/wp/v2/pages/?slug=`,
    GET_POSTS: `${api}/wp-json/wp/v2/posts`,
    GET_POST: `${api}/wp-json/wp/v2/posts`,
    GET_SINGLE_POST: `${api}/wp-json/wp/v2/post`,
    GET_RANDOM: `${api}/wp-json/wp/v2/posts`
  },
  PARTNERS: 'partners',
  HOME: `${api}/wp-json/wp/v2/pages/?slug=home-page`,
  HEADER: `${api}/wp-json/acf/v3/options/options`,
  DASHBOARD: {
    LAST_ORDER: `${api}/wp-json/salve/v1/user/last_order`,
    LAST_ORDERS: `${api}/wp-json/salve/v1/user/orders?page=1&limit=20&order=DESC&order_by=date`,
    MONTHLY_ALLOTMENT: '',
    LOYALTY_POINTS: `${api}/wp-json/salve/v1/user/loyalty_points`,
    USER: `${api}/wp-json/salve/v1/user`,
    ORDER: `${api}/wp-json/salve/v1/order`,
    ORDERS: `${api}/wp-json/salve/v1/user/orders`
  },
  PRODUCTS: {
    GET_PRODUCT_BY_ID: `${api}/wp-json/salve/v1/product?salve_id=`,
    GET_PRODUCT_BY_TYPE: `${api}/wp-json/salve/v1/product_by_type?type=`,
    GET_RELATED: `${api}/wp-json/salve/v1/related`
  },
  PAGES: {
    GET_ABOUT: `${api}/wp-json/wp/v2/pages/?slug=about`,
    GET_LOYALTY: `${api}/wp-json/wp/v2/pages/?slug=loyalty-program`,
    GET_MEDIA: `${api}/wp-json/wp/v2/pages/?slug=media-events`
  },
  CART: {
    ADD_PRODUCT: `${api}/wp-json/cocart/v2/cart/add-item/?cart_key=`,
    GET_CART: `${api}/wp-json/cocart/v2/cart/`,
    GET_CART_ITEMS: `${api}/wp-json/cocart/v2/cart/items`,
    CLEAR_CART: `${api}/wp-json/cocart/v2/cart/cleardddd`,
    UPDATE_QTY: `${api}/wp-json/cocart/v2/cart/item`,
    GET_CART_TOTALS: `${api}/wp-json/cocart/v2/cart/totals`,
    REMOVE_ITEM: `${api}/wp-json/cocart/v2/cart/item`,
    GET_CART_COUNT: `${api}/wp-json/cocart/v2/cart/items/count`
  },
  CONTACT: {
    SUBMIT: `${api}/wp-json/salve/v1/cms/page/contact/submissions`
  },
  INTERNAL_API: {
    GET_META: '/api/_meta'
  }
}

export const ENUM_PLATFORM_ENDPOINT = {
  SHOP: {
    GET_ALL_PRODUCTS: `${salve}/api/products`,
    GET_ALL_BRANDS: `${salve}/api/brands`,
    GET_ALL_SUPPLIERS: `${salve}/api/suppliers`,
    GET_ALL_STRAINS: `${salve}/api/strains`,
    GET_SHOP_FILTERS_DATA: `${salve}/api/utils/products/filters`
  },
  REGION: {
    GET_REGION: `${salve}/api/locations?address='123%20Main%20St.%20Miami%20FL%2033181'`
  }
}

export const createUrl = (endPoint, version = ENUM_VERSION_API.V1, client = 'client') =>
  `/api/${version}/${client}/${endPoint}`
