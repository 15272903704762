import components_plugin_KR1HBZs4kY from "/home/forge/theflowery.co/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/forge/theflowery.co/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/home/forge/theflowery.co/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/home/forge/theflowery.co/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/forge/theflowery.co/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OrkQhMqHci from "/home/forge/theflowery.co/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/forge/theflowery.co/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import radar_Z9bViDyHJD from "/home/forge/theflowery.co/plugins/radar.js";
import _1_sentry_fvvfZqBNbc from "/home/forge/theflowery.co/plugins/1.sentry.js";
import _2_vuex_jgMjJyRGLb from "/home/forge/theflowery.co/plugins/2.vuex.js";
import async_scripts_client_NBeWfZTZXc from "/home/forge/theflowery.co/plugins/async-scripts.client.js";
import bootstrap_gtag_client_uVmv9xQXp0 from "/home/forge/theflowery.co/plugins/bootstrap-gtag.client.js";
import corrected_image_url_8d9SQpnuGV from "/home/forge/theflowery.co/plugins/corrected-image-url.js";
import get_shop_controls_client_l3nknzp72Y from "/home/forge/theflowery.co/plugins/get-shop-controls.client.js";
import get_token_client_xeam5QzgYx from "/home/forge/theflowery.co/plugins/get-token.client.js";
import herb_client_OqLX3zKgVA from "/home/forge/theflowery.co/plugins/herb.client.js";
import ml_smart_banner_client_LZGFZwUDHk from "/home/forge/theflowery.co/plugins/ml-smart-banner.client.js";
import schema_R1x7iuiI1n from "/home/forge/theflowery.co/plugins/schema.js";
import vue_slider_client_Enda6L0PTi from "/home/forge/theflowery.co/plugins/vue-slider.client.js";
import vue_social_sharing_client_fo5A0ATFzV from "/home/forge/theflowery.co/plugins/vue-social-sharing.client.js";
import vue3_marquee_client_iewocvkFyQ from "/home/forge/theflowery.co/plugins/vue3-marquee.client.js";
import yotpo_client_dIRJnnd9TG from "/home/forge/theflowery.co/plugins/yotpo.client.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_OrkQhMqHci,
  chunk_reload_client_UciE0i6zes,
  radar_Z9bViDyHJD,
  _1_sentry_fvvfZqBNbc,
  _2_vuex_jgMjJyRGLb,
  async_scripts_client_NBeWfZTZXc,
  bootstrap_gtag_client_uVmv9xQXp0,
  corrected_image_url_8d9SQpnuGV,
  get_shop_controls_client_l3nknzp72Y,
  get_token_client_xeam5QzgYx,
  herb_client_OqLX3zKgVA,
  ml_smart_banner_client_LZGFZwUDHk,
  schema_R1x7iuiI1n,
  vue_slider_client_Enda6L0PTi,
  vue_social_sharing_client_fo5A0ATFzV,
  vue3_marquee_client_iewocvkFyQ,
  yotpo_client_dIRJnnd9TG
]