import { ENUM_END_POINT } from '@/utils/api/const'
import { ApiRequest } from '@/utils/api/api.request'
import { requestPipe } from '@/utils/api/api-pipes'

export class UserRequests extends ApiRequest {
  getUser() {
    return this.get(`${ENUM_END_POINT.USER}`).then(requestPipe)
  }

  updateUser(data) {
    return this.put(`${ENUM_END_POINT.DASHBOARD.USER}`, data).then(requestPipe)
  }
}
