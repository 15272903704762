import { ENUM_END_POINT } from '@/utils/api/const'
import { ApiRequest } from '@/utils/api/api.request'
// import { requestPipe } from "../api-pipes";

export class HeaderRequests extends ApiRequest {
  getHeaderData() {
    return this.get(`${ENUM_END_POINT.HEADER}`)
    // .then(requestPipe)
  }
}
