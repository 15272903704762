import environment from '@/environment'
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV !== 'development',
    app: nuxtApp.vueApp
    // @Note: Rate limiting via Sentry admin is probably better.
    // https://docs.sentry.io/platforms/javascript/configuration/sampling/
    // sampleRate: 0.25
  })
})
