import { ApiRequest } from '@/utils/api/api.request'
import { ENUM_END_POINT } from '@/utils/api/const'
import { requestPipe } from '@/utils/api/api-pipes'
import { reformatQuery } from '@/utils/api/api-utils'

export class ProfileRequests extends ApiRequest {
  lastOrders(page = 1) {
    return this.get(
      ENUM_END_POINT.DASHBOARD.LAST_ORDERS +
        `${reformatQuery({
          page
        })}`
    ).then(requestPipe)
  }

  lastOrder() {
    return this.get(ENUM_END_POINT.DASHBOARD.LAST_ORDER).then(requestPipe)
  }

  getOrder(id) {
    return this.get(`${ENUM_END_POINT.DASHBOARD.ORDER}/${id}`).then(requestPipe)
  }

  getOrders(page = 1, limit = 20) {
    return this.get(`${ENUM_END_POINT.DASHBOARD.ORDER}?page=${page}&limit=${limit}&order=DESC&order_by=date`).then(
      requestPipe
    )
  }

  loyaltyPoints() {
    return this.get(ENUM_END_POINT.DASHBOARD.LOYALTY_POINTS).then(requestPipe)
  }

  getUser() {
    return this.get(ENUM_END_POINT.DASHBOARD.USER).then(requestPipe)
  }
}
