const state = () => ({
  token: false
})

const mutations = {
  setToken(state, value) {
    state.token = value
  }
}

const getters = {
  hasToken(state) {
    return state.token
  }
}

export default {
  state,
  mutations,
  getters
}
