import Radar from 'radar-sdk-js'
import 'radar-sdk-js/dist/radar.css'

export default defineNuxtPlugin(async (nuxtApp) => {
  if (process.server) return // Only run in the client

  try {
    let apiKey = ''
    const response = await fetch('/api/radar')
    const data = await response.json()
    if (data.success) {
      apiKey = data.apiKey
    } else {
      console.log(response.message || 'Failed to fetch Radar API key')
    }
    if (process.client) {
      // Initialize Radar SDK with your public API key
      Radar.initialize(apiKey)

      // Provide Radar globally so that you can use `this.$radar` in your components
      nuxtApp.provide('radar', Radar)
    }
  } catch (err) {
    console.log(response.message || 'Failed to fetch Radar API key')
  }
})
