/**
 * Stores authentication key in localstorage
 */
export default function StoreAuthTokenInLocalstorage(token = null) {
  if (!token) return

  // console.log("StoreAuthTokenInLocalstorage", token);
  localStorage.setItem('token', token)
  return true
}
