/**
 * Capitalizes the first letter of the provided string
 * @param string
 * @return String
 *
 * Examples
 * "bill gates" becomes  "Bill gates"
 */
export default function Capitalize(str) {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}
