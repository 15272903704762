export default {
  namespaced: true,
  state: {
    changeModal: null
  },
  mutations: {
    SET_CHANGE_MODAL_HANDLER(state, handler) {
      state.changeModal = handler
    }
  }
}
