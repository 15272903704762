import { ENUM_ACQUISITION_METHODS } from '@/store/user-region/user-region'

export const LANG_ACQUISITION_LABELS = {
  [ENUM_ACQUISITION_METHODS.DELIVERY]: 'Delivery',
  [ENUM_ACQUISITION_METHODS.PICKUP]: 'Pick-up'
}

export const LANG_NO_LOCATIONS_WITH_PICKUP = 'There are no locations currently accepting pick-up orders.'

export const arrToList = (arr) => {
  return arr.length === 1 ? arr[0] : [arr.slice(0, arr.length - 1).join(', '), arr[arr.length - 1]].join(' and ')
}
