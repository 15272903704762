// plugins/smart-app-banner.client.js
export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    // Since this is a client-side plugin, you can safely import and use your SmartAppBanner here
    import('ml-smart-banner')
      .then(({ default: SmartAppBanner }) => {
        // Initialize SmartAppBanner with desired options
        const banner = new SmartAppBanner({
          // Configuration options for your SmartAppBanner
          // Example configuration, adjust according to your needs:
          fontFamily: `DM Mono, serif`, // (string) Font family for banner texts, defaults to system safe fonts
          fallbackFontFamily: 'sans-serif', // (string) Font family for fallback icon, safe options are serif and sans-serif
          appName: 'ML', // (string) Initials for fallback icon.  Reccommended 2 characters. Fallback Image uses button text and bg color
          textColor: '#222', // (string) Banner texts color (any color property value)
          buttonColor: '#fff', // (string) Button color (any background property value)
          buttonText: '', // (string) Button text
          buttonTextColor: '#fff', // (string) Button Text Color (any color property value)
          iconUrl: '/images/icons/ic-smartbutton.png',
          textHeading: 'THE FLOWERY', // (string) Heading Text
          textDescription: 'Click To Download The Flowery App', // (string) Description text
          bannerColor: '#fff', // (string) Banner BG color
          linkIos: 'https://apps.apple.com/us/app/the-flowery/id6451003120', // (string) Link for iOS
          linkAndroid: 'https://play.google.com/store/apps/details?id=com.theflowery.android', // (string) Link for Android
          position: 'top', // (string) Position of the banner, default 'top'. 'top' | 'bottom'
          animation: 'fadeIn', // (string) Banner animation, default 'fadeIn'. 'fadeIn' | 'scaleUp' | 'slideBottom' | 'slideTop' | 'slideLeft' | 'slideRight' | null,
          display: 'onLoad', // (string) Display options, default 'onLoad'. 'onLoad' | 'onScrollDown' | 'onScrollUp'
          radius: '0', // (string) Banner radius with units
          delay: 0, // (number) defines how much time to wait until the element shows up
          shadow: true, // (boolean) If true applies soft shadow, true | false
          useSession: true, // (boolean) If true, after closed, Banner is not shown upon page reaload. Default: true
          zindex: 999999 // (number) Sets the z-index of the element
        })

        function getMobileOS() {
          const userAgent = navigator.userAgent || navigator.vendor || window.navigator.userAgent

          if (/windows phone/i.test(userAgent)) {
            return 'windows'
          }
          if (/android/i.test(userAgent)) {
            return 'android'
          }
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'ios'
          }

          return 'unknown' // Default return value
        }

        if (getMobileOS() !== 'unknown' && getMobileOS() !== 'ios') {
          document.querySelector('.ml-smartBanner').classList.add('ml-smartBanner-toggle--visible')
        }

        // Call the getMobileOS method
      })
      .catch((error) => console.error('Failed to load SmartAppBanner', error))
  }
})
