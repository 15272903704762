/* eslint-disable */
export default {
  namespaced: true,

  state: () => ({}),

  getters: {},

  actions: {
    /**
     * Subscribes user to the flowery newsletter
     * @param name the users name
     * @param email the users email
     * @returns {Promise<*>}
     */
    async xSubscribeToNewsletter(context, { name, email }) {
      console.log('xSubscribeToNewsletter called', name, email)
      let url = '/wp-json/salve/v1/user/newsletter'

      try {
        const response = await context.dispatch('http/post', { url, data: { name, email } }, { root: true })
        console.log('xSubscribeToNewsletter received response', response)
        return response.data
      } catch (e) {
        throw new Error(e)
      }
    },

    /**
     * Fetches social metadata for the provided page
     * @param context vuex context variable
     * @param page a string containing the name of the page
     * @returns {*[]}
     */
    async xFetchPageMeta(context, page) {
      let url = `/wp-json/wp/v2/pages/?slug=${page}`
      console.log('xFetchPageMeta called', url)

      try {
        const response = await context.dispatch('http/get', { url, data: {} }, { root: true })
        // console.log("xFetchPageMeta received response", response);
        return response.data
      } catch (e) {
        throw new Error(e)
      }
    },

    /**
     * Fetches social metadata for the provided page
     * @param context vuex context variable
     * @param page a string containing the name of the page
     * @returns {*[]}
     */
    async xFetchPostMeta(context, page) {
      const slug = page.replace(/\&/gi, '')

      // console.log("xFetchPostMeta called", page, slug);

      let url = `/wp-json/wp/v2/posts/?slug=${slug}`

      try {
        const response = await context.dispatch('http/get', { url, data: {} }, { root: true })
        // console.log("xFetchPostMeta received response", response);
        return response.data
      } catch (e) {
        throw new Error(e)
      }
    },

    /**
     * Fetches social metadata for the provided page
     * @param context vuex context variable
     * @param page a string containing the name of the page
     * @returns {*[]}
     */
    async xFetchPostWithSlug(context, id) {
      const slug = id.replace(/\&/gi, '')

      // console.log("xFetchPostWithSlug called", id, slug);

      let url = `/wp-json/wp/v2/posts?slug=${slug}`

      try {
        const response = await context.dispatch('http/get', { url, data: {} }, { root: true })
        // console.log("xFetchPostWithSlug received response", response.data);
        return response.data[0]
      } catch (e) {
        throw new Error(e)
      }
    },

    /**
     * Fetches a page from server
     * @param context vuex context variable
     * @param page a string containing the name of the page
     * @returns {*[]}
     */
    async xFetchPage(context, page) {
      let url = `/wp-json/wp/v2/pages/${page}`
      try {
        const response = await context.dispatch('http/get', { url, data: {} }, { root: true })
        return response.data
      } catch (e) {
        throw new Error(e)
      }
    }
  },

  mutations: {}
}
