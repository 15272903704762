import { ENUM_END_POINT } from '@/utils/api/const'
import { ApiRequest } from '@/utils/api/api.request'
import { requestPipe } from '@/utils/api/api-pipes'

export class ProductRequests extends ApiRequest {
  getProductById(id) {
    return this.get(`${ENUM_END_POINT.PRODUCTS.GET_PRODUCT_BY_ID}${id}`).then(requestPipe)
  }

  getProductByType(type) {
    return this.get(`${ENUM_END_POINT.PRODUCTS.GET_PRODUCT_BY_TYPE}${type}`).then(requestPipe)
  }

  getRelated(productId, locationId) {
    if (!productId) throw new Error('Required params missing.')

    const endpoint = new URL(ENUM_END_POINT.PRODUCTS.GET_RELATED)

    endpoint.searchParams.append('id', productId)
    if (locationId) endpoint.searchParams.append('location_id', locationId)

    return this.get(endpoint.href).then(requestPipe)
  }
}
