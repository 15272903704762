/*
  This is a custom plugin to add vuex support in Nuxt3.
 */
import { defineNuxtPlugin } from '#imports'
import { useHydration } from '#app'
import { newStore } from '@/store'
import { createDefaultCartState } from '@/store/cart/cart'

export default defineNuxtPlugin((nuxtApp) => {
  const store = newStore()

  nuxtApp.vueApp.use(store)

  const router = nuxtApp.vueApp.config.globalProperties.$router
  router.beforeEach(() => {
    store.dispatch('xCloseMobileNavbar')
  })

  const createVuexSnapshot = () => {
    return store._state.data
  }
  const restoreVuexSnapshot = (snapshot) => {
    // Defensively hard reset the cart store module prior to restoring snapshot client side.
    snapshot.cart = createDefaultCartState()
    store.replaceState(snapshot)
  }

  useHydration('vuex', createVuexSnapshot, restoreVuexSnapshot)
})
