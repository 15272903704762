import dayjs from 'dayjs'

import localeEN from 'dayjs/locale/en'

import dayjsUTC from 'dayjs/plugin/utc'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsIsTomorrow from 'dayjs/plugin/isTomorrow'
import dayjsIsToday from 'dayjs/plugin/isToday'
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.locale(localeEN)

dayjs.extend(dayjsUTC)
dayjs.extend(dayjsTimezone)
dayjs.extend(dayjsIsTomorrow)
dayjs.extend(dayjsIsToday)
dayjs.extend(dayjsAdvancedFormat)

export { dayjs }
