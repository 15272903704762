/* eslint-disable */
import DeleteAuthTokenFromLocalstorage from '@/store/auth/utilities/DeleteAuthTokenFromLocalstorage'
import StoreAuthTokenInLocalstorage from '@/store/auth/utilities/StoreAuthTokenInLocalstorage'
import DeleteCartKeyFromLocalstorage from '@/store/cart/utilities/DeleteCartKeyFromLocalstorage'
import StoreAgeVerificationInLocalStorage from '@/store/auth/utilities/StoreAgeVerificationInLocalStorage'
import Capitalize from '@/utils/filters/capitalize'
import Validator from '@/utils/validation'

/**
 * This module handles logging in and logging out of the application
 */
export default {
  namespaced: true,

  state: () => ({
    // The access token returned from server
    token: null,

    // If true we show the popup modal form that allows users to login to the system
    showLoginPopup: false,

    // If false we show the popup modal form that confirms the user is 21 or over
    isAdult: false,

    /** if true there was an error attempting to login */
    hasError: null,

    /** Set to true when the user registers their account for the first time to show a welcome screen */
    newRegistration: false,

    stateKnown: false
  }),

  getters: {
    /**
     * Returns access token you can use to connect to WP API
     */
    xAccessToken(state) {
      if (state.token !== null) {
        return state.token.access_token
      }

      return null
    },

    /**
     * Returns true if auth access token is saved to module state
     */
    xHasAccessToken(state) {
      const ret = state.token !== null
      // console.log("has access token", ret);
      return ret
    },

    /**
     * Should the app show the login popup
     */
    xShouldShowLoginPopup(state) {
      return state.showLoginPopup
    },

    /**
     * Should the app show the age verification popup
     */
    xShouldShowAgePopup(state) {
      //console.log("runing xShouldShowAge", !state.isAdult);
      return !state.isAdult
    },

    /**
     * Returns true if login error happened
     */
    xHasLoginError(state) {
      return !!state.hasError
    },

    /**
     * Get the login error
     */
    xLoginErrorMessage(state) {
      return state.hasError
    },

    /**
     * Returns true if a new account has been registered
     * @returns {boolean}
     */
    xNewRegistration(state) {
      return state.newRegistration
    },

    xAuthStateKnown(state) {
      return state.stateKnown
    }
  },

  actions: {
    xFakeRegistration(context) {
      console.warn('Fake Registration Happened')
      return {
        code: 200,
        message: 'FAKE Registration was Successful',
        token:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvdGVzdC1hcGkudGhlZmxvd2VyeS5jbyIsImlhdCI6MTY1MDk5MTY3NiwibmJmIjoxNjUwOTkxNjc2LCJleHAiOjE2NTE1OTY0NzYsImRhdGEiOnsidXNlciI6eyJpZCI6MSwiZGV2aWNlIjoiIiwicGFzcyI6IjQ4OGMxNmE3NTJlMjhkYmE4YzA1YWQzZDRiZWYwZjgyIn19fQ.yxiASx11qxuRkza1wDAEZCTyQmUwH_m5A0r63sM_esU'
      }
    },

    /**
     * Closes the login modal
     */
    xCloseLoginPopup({ commit }) {
      // console.log("auth x close login popup");
      commit('CLOSE_LOGIN_POPUP')
    },

    /**
     * Opens a popup modal allowing you to login to the app
     */
    xOpenLoginPopup({ commit, dispatch }) {
      // console.log("auth x open login popup");
      dispatch('xCloseMobileNavbar', {}, { root: true })
      commit('OPEN_LOGIN_POPUP')
    },

    /**
     * Logs out the currently authenticated user. Returns a promise that can be caught in user application space to do
     * something after login. An example might be to redirect to the home page or login page of the site or something
     */
    async xLogout({ dispatch, getters, commit }) {
      // console.log("x logout");
      DeleteAuthTokenFromLocalstorage()
      // DeleteCartKeyFromLocalstorage();
      commit('DELETE_AUTH_TOKEN')
      await dispatch('authuser/xRemoveAuthUserFromState', {}, { root: true })
      await dispatch('cart/xFetchCart', {}, { root: true })
    },

    async xLogin({ dispatch, commit, getters }, { username, password }) {
      const url = '/wp-json/jwt-auth/v1/token'
      const retval = { success: true, msg: '' }

      // console.log("x login lol", username, password);

      if (getters.xHasAccessToken) {
        // console.log("already logged in", getters.xAccessToken);
        return
      }

      // Remove hasError state before attempting login
      commit('RESET_HASERROR')

      const response = await dispatch('http/post', { url, data: { username, password } }, { root: true }).catch(
        (error) => {
          console.error('o no something bad happen lol', { ...error })
          retval.success = false
          retval.msg = error?.response?.data?.message
          dispatch(
            'notifications/xAddErrorMessage',
            { message: retval.msg, seconds: 10, type: 'login' },
            { root: true }
          )
          commit('LOGIN_ERROR', error?.response?.data?.message || 'Unknown Error')
        }
      )

      // console.log("response from login was ", response);

      if (response) {
        console.log('i logged in')

        // Store auth token in localstorage and module state
        commit('LOCALSTORAGE_SET_AUTH_TOKEN', {
          token: response?.data?.data?.token
        })
        commit('SET_AUTH_TOKEN', response?.data?.data?.token)

        // Pull the user from the server
        const user = await dispatch('authuser/xFetchAuthenticatedUser', {}, { root: true })

        // Merge the cart
        await dispatch('cart/xFetchCart', { useKey: true }, { root: true })

        // Close the login popup modal
        commit('CLOSE_LOGIN_POPUP')

        // Show a welcome message to the user
        console.log('SDFSADFASDFASDFASDFASDFASDFASFS USER IS', user)

        const message = {
          message: `Welcome Back ${Capitalize(user?.first_name || '')}!`
        }
        dispatch('notifications/xAddNotification', message, { root: true })

        if (user?.first_name && user?.email) {
          klaviyo
            .identify({
              email: user.email,
              first_name: user.first_name,
              last_name: user.last_name
            })
            .then(() => console.log('Identify has been completed'))

          // Get the user ID and set it as the external user ID for OneSignal
          let userId = user.email
          if (typeof nativeFunctions !== 'undefined') {
            nativeFunctions.onesignalSetExternalUserId(userId)
          }
        }

        // Move any temporary addresses to the user object
        await dispatch('authuser/xAddTempAddressToAuthUser', {}, { root: true })
      }

      return retval
    },

    /**
     * Sends a code to the provided email that can be used to reset password
     * @param email
     */
    async xResetPassword({ dispatch, commit, getters }, { email }) {
      const url = '/wp-json/bdpwr/v1/reset-password'
      const retval = { success: true, msg: '' }

      console.log('x reset password', email)

      const response = await dispatch('http/post', { url, data: { email } }, { root: true }).catch((error) => {
        console.error("couldn't reset password", { ...error })
        retval.success = false
        retval.msg = error?.response?.data?.message
        commit('LOGIN_ERROR', error?.response?.data?.message || 'Unknown Error')
      })

      console.log('response from reset password was ', response)

      return retval
    },

    /**
     * Confirms the code sent by xResetPassword
     * @param email the users email
     * @param code the confirmation code
     * @param password the new password
     * @param repeat same as password to confirm
     */
    async xConfirmResetPassword({ dispatch }, { email, code, password, repeat }) {
      console.log('x confirm reset password', email, code, password, repeat)
      const url = '/wp-json/bdpwr/v1/set-password'
      const retval = {
        success: true,
        msg: '',
        validation: new Validator(
          { email, code, password, repeat },
          {
            email: 'required|email',
            code: 'required|string',
            password: 'required|string',
            repeat: 'required|string|same:password'
          }
        )
      }

      if (retval.validation.passes()) {
        const response = await dispatch('http/post', { url, data: { email, code, password } }, { root: true }).catch(
          (error) => {
            console.error("couldn't confirm reset password", { ...error })
            retval.success = false
            retval.msg = error?.response?.data?.message
          }
        )

        console.log('response from reset password was ', response)

        if (response) {
          const loginResponse = await dispatch('xLogin', {
            username: email,
            password
          })
          console.log('login response is', loginResponse)
        }
      } else {
        retval.success = false
      }

      return retval
    }
  },

  mutations: {
    /**
     * Commits an access token sent by the server to our internal state
     * @param payload the auth token
     */
    SET_AUTH_TOKEN(state, token) {
      // console.log("SET_AUTH_TOKEN SETTING TOKEN TO", token);
      state.token = { access_token: token }
    },

    DELETE_AUTH_TOKEN(state) {
      // console.log("DELETE_AUTH_TOKEN");
      state.token = null
    },

    SET_STATE_KNOWN(state, val) {
      state.stateKnown = val
    },

    /**
     * Commits an auth token to local storage
     * @param payload the auth token
     */
    LOCALSTORAGE_SET_AUTH_TOKEN(state, { token, storeAuthToken = StoreAuthTokenInLocalstorage }) {
      // console.log("LOCALSTOARAGE_SET_AUTH_TOKEN SETTING TOKEN TO", token);
      storeAuthToken(token)
    },

    /** Sets show login state var to true which will in turn make the app reactively render the login modal */
    OPEN_LOGIN_POPUP(state) {
      state.showLoginPopup = true
    },

    /** Sets show login state var to false which will in turn make the app stop renderring the login modal */
    CLOSE_LOGIN_POPUP(state) {
      state.showLoginPopup = false
    },

    /** Resets the hasError state */
    RESET_HASERROR(state) {
      state.hasError = null
    },

    /** Save the error in login state */
    LOGIN_ERROR(state, error) {
      state.hasError = error
    },

    /** Set the isAdult variable that shows/hides the age verify modal */
    SET_IS_ADULT(state, isAdult = true) {
      state.isAdult = isAdult
      StoreAgeVerificationInLocalStorage(isAdult)
    },

    /**
     * Set a flag indicating a user has registered a new account. Used to display a welcome message
     * @param payload the auth token
     */
    NEW_REGISTRATION(state, token) {
      state.newRegistration = true
    }
  },

  // nested modules
  modules: {}
}
