import MakeProductURL from './utilities/MakeProductURL'

const LOCAL_ON_SALE_FILTER = {
  specials: [
    {
      label: 'On Sale',
      value: 'is_on_sale'
    }
  ]
}

const LOCAL_FILTERS_MAP = {
  is_on_sale: LOCAL_ON_SALE_FILTER
}

/**
 * Creates a pagination object with default settings
 */
function CreateDefaultPagination() {
  // A pagination object from the server will have other fields that aren't included here but we don't need them
  return {
    current_page: 1,
    last_page: 1,
    per_page: 100
  }
}

let cancelXFetchProducts = null

/**
 * This module handles logging in and logging out of the application
 */
export default {
  namespaced: true,

  state: () => ({
    // A keyed object of params you can use to filter products in the shop
    controls: {},

    // New controls for the shop
    newcontrols: {},

    // How long should we wait before refreshing shop controls
    controlRefreshInterval: 300000, // 5 min

    // The last used query for fetching products
    lastQuery: {},

    // The list of products fetched from server
    products: {},

    // Pagination data for the current query
    pagination: CreateDefaultPagination()
  }),

  getters: {
    /** A list of options you can use to query the product database */
    xShopControls(state) {
      return state.controls
    },

    /** A list of options you can use to query the product database */
    xShopNewControls(state) {
      return state.newcontrols
    },

    /** A list of products fetched from the database
     * @return Array
     */
    xProducts(state) {
      return Object.values(state.products)
    },

    /** A list of products fetched from the database keyed by slug
     * @return Array
     */
    xKeyedProducts(state) {
      return state.products
    },

    xLastQuery(state) {
      return state.lastQuery
    },

    /**
     * Returns pagination data (current page, total number of pages, etc) for the current query
     */
    xCurProductPage(state) {
      return state.pagination
    }
  },

  actions: {
    /**
     * Pulls shop controls from localstorage into module state
     */
    async xInitShopControls({ dispatch, commit }) {
      // commit("SET_SHOP_CONTROLS", GetShopControlsFromLocalStorage());
      dispatch('xRefreshShopControls')
    },

    /**
     * Checks to see if we have waited long enough before refreshing shop controls and if so, makes a server request
     * to pull down new controls.
     */
    async xRefreshShopControls({ dispatch, commit }) {
      // const refreshAt = GetControlRefreshTime();
      // const currentTime = parseInt(Date.now());
      //
      // if(currentTime > refreshAt) {
      dispatch('xFetchShopControls')
      dispatch('xFetchNewShopControls')
      // }
      // else {
      //   console.log(`no refresh for ${refreshAt - currentTime} milliseconds`);
      // }
    },

    /**
     * Fetch a key/val list of shop filters from server. shop filters are keys that you can use to search/filter
     * products on the backend server. Use a front end UI to choose from these options then pass your chosen
     * query filters to the fetchProducts method
     */

    async xFetchShopControls(context) {
      const url = `/api/utils/products/filters`
      try {
        const { request } = await context.dispatch('platformhttp/get', { url }, { root: true })
        const response = await request()
        context.commit('SET_SHOP_CONTROLS', response?.data?.data || {})
        context.commit('LOCALSTORAGE_SET_SHOP_CONTROLS', response?.data?.data || {})
      } catch (error) {
        console.error('problem xFetchShopControls', error)
      }
    },

    async xFetchNewShopControls(context) {
      const url = `/api/products/flowery/filters`
      try {
        const { request } = await context.dispatch('platformhttp/get', { url }, { root: true })
        const response = await request()
        context.commit('SET_NEW_SHOP_CONTROLS', response?.data || {})
        context.commit('LOCALSTORAGE_SET_NEW_SHOP_CONTROLS', response?.data || {})
      } catch (error) {
        console.error('Problem fetching new shop controls:', error)
      }
    },

    /**
     * Fetch products from the backend database
     * @param context vuex context variable
     * @param query a query obj derived from the controls pulled from the xFetchControls endpoint. If the query is
     * empty we return the default query (likely a list of products for the front page but thats up to you)
     * @param appendResults should results be appended to already fetched products or overwritten
     */
    async xFetchProducts(context, { query = {}, appendResults = false }) {
      if (cancelXFetchProducts) cancelXFetchProducts()
      const url = MakeProductURL('/api/products', query)
      try {
        const { request, cancelRequest } = await context.dispatch('platformhttp/get', { url }, { root: true })

        cancelXFetchProducts = cancelRequest
        const response = await request()

        context.commit('LAST_QUERY', query)
        context.commit('FETCHED_PRODUCTS', {
          products: response?.data?.data || [],
          appendResults
        })
        context.commit('PRODUCT_PAGINATION', response?.data?.meta || CreateDefaultPagination())
        setTimeout(() => window.yotpoWidgetsContainer.initWidgets(), 500) // Delay to ensure widgets are ready
      } catch (error) {
        console.error('problem xFetchProducts', error)
      }
    },

    /**
     * Fetch products from the backend database and save it to the store
     * @param context vuex context variable
     * @param query a query obj derived from the controls pulled from the xFetchControls endpoint. If the query is
     */
    async xFetchProductsList(context, { query = {} }) {
      console.log(query)
      if (cancelXFetchProducts) cancelXFetchProducts()
      const url = MakeProductURL('/api/products', query)
      console.log(url)
      try {
        const { request, cancelRequest } = await context.dispatch('platformhttp/get', { url }, { root: true })
        cancelXFetchProducts = cancelRequest
        const response = await request()

        return response.data.data
      } catch (error) {
        console.error('problem xFetchProductsList', error)
      }
    },

    xClearProducts(context) {
      context.commit('FETCHED_PRODUCTS', {
        products: [],
        appendResults: false
      })
    }
  },

  mutations: {
    /**
     * Set the new controls, completely independent of the existing 'controls' state.
     */
    SET_NEW_SHOP_CONTROLS(state, newFilters) {
      state.newcontrols = newFilters
    },

    LOCALSTORAGE_SET_NEW_SHOP_CONTROLS(state, newFilters) {
      if (localStorage) {
        localStorage.setItem('NEW_SHOP_CONTROLS', JSON.stringify(newFilters))
        localStorage.setItem('NEW_SHOP_CONTROLS_REFRESHED_AT', Date.now())
      }
      state.newcontrols = newFilters
    },

    /**
     * Commits the last query used to fetch from the database to module state. Useful for figuring out what product
     * @param query the query you used to sync to the database
     */
    LAST_QUERY(state, query = {}) {
      state.lastQuery = query
    },

    /**
     * Commit pagination data for the current query
     * @param pagination pagination data from the server. Look at CreateDefaultPagination for an idea of what this
     *                   data looks like
     */
    PRODUCT_PAGINATION(state, pagination) {
      state.pagination = pagination
    },

    /**
     * Commit products fetched from server to module state. Products are stored in an object
     * keyed by their slug for quick access
     */
    FETCHED_PRODUCTS(state, { products = [], appendResults = false }) {
      let retval = {}

      Object.values(products).forEach((product) => {
        if (product?.slug) {
          retval[product.slug] = product
        }
      })

      if (appendResults) {
        retval = { ...state.products, ...retval }
      }

      state.products = retval
    },

    LOCALSTORAGE_SET_SHOP_CONTROLS(state, filters) {
      if (localStorage) {
        localStorage.setItem('SHOP_CONTROLS', JSON.stringify(filters))
        localStorage.setItem('SHOP_CONTROLS_REFRESHED_AT', Date.now())
      }
    },

    /**
     * Set the filters that can be used to search for products
     * @param filters a list of filters
     */
    SET_SHOP_CONTROLS(state, filters) {
      let controls = {}

      /** iterate through the filters looking for things like max_xyz & min_xyz filters that are in the root of the
       *  object. we convert these to objects that look something like this
       *  {
       *    xyz: {
       *      min: 0,
       *      max: 1000
       *    }
       *  }
       */

      Object.keys(filters).forEach((key) => {
        if (key in LOCAL_FILTERS_MAP) {
          controls = Object.assign(controls, LOCAL_FILTERS_MAP[key])
        }

        const split = key.split('_')
        // if we have a key called max/min we split it into an object
        if (split?.[0] === 'max' || split?.[0] === 'min') {
          // if object doesn't exist create it from the right hand side of the split filter key. In the example above
          // min_xyz, the key would be 'xyz' because we split at the underscore
          if (!controls?.[split[1]]) {
            controls[split[1]] = {}
          }

          controls[split[1]][split[0]] = Math.ceil(filters[key])
        } else {
          // otherwise just add the key as is
          controls[key] = filters[key]
        }
      })

      state.controls = controls
    }
  },

  // nested modules
  modules: {}
}
