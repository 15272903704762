const state = () => ({
  user: false
})

const mutations = {
  setUser(state, value) {
    state.user = value
  }
}

const getters = {
  getUser(state) {
    return state.user
  }
}

export default {
  state,
  mutations,
  getters
}
