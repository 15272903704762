import axios from 'axios'

import { UrlInterceptor } from '@/utils/api/interceptors/url.interсeptor'
import { TokenInterceptor } from '@/utils/api/interceptors/token.interсeptor'
import { ResponseInterceptor } from '@/utils/api/interceptors/response.interсeptor'
import { ErrorInterceptor } from '@/utils/api/interceptors/error.interсeptor'

const createApi = () => {
  const axiosInstance = axios.create({
    // baseURL: environment.API_URL,
  })

  axiosInstance.interceptors.request.use(UrlInterceptor)
  axiosInstance.interceptors.request.use(TokenInterceptor)

  axiosInstance.interceptors.response.use(ResponseInterceptor, ErrorInterceptor)

  return axiosInstance
}

export const $axios = createApi()
