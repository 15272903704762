export const requestPipe = (response) => response?.data

export const requestPaginatePipeObject = (response) => {
  return {
    paginate: response,
    paginateData: response?.data || []
  }
}

export const requestPaginatePipe = (response) => response?.data?.data?.data || []
