import MakeCartURL from '@/store/cart/utilities/MakeCartURL'
import FindCartKey from '@/store/cart/utilities/FindCartKey'
import _money from '@/utils/filters/money'
import { arrToList } from '@/utils/lang'
import { LOCAL_STORAGE_CART_KEY } from '@/store/cart/config'
import product from '~/store/product/product'
import { $api } from '@/utils/api'

/**
 * Creates an empty shopping cart that won't throw errors but doesn't contain any products
 */
function CreateEmptyShoppingCart() {
  return {
    items: [],
    coupons: [],
    totals: {
      subtotal: '0',
      subtotal_tax: '0',
      fee_total: '0',
      fee_tax: '0',
      discount_total: '0',
      discount_tax: '0',
      shipping_total: '0',
      shipping_tax: '0',
      total: '0',
      total_tax: '0'
    }
  }
}

export function createDefaultCartState() {
  return {
    // Has the cart been fetched from server
    cartLoaded: false,
    cartData: CreateEmptyShoppingCart(),
    cartKey: null
  }
}

export default {
  namespaced: true,

  state: () => createDefaultCartState(),

  getters: {
    /** False if cart hasnt been loaded yet so we can hide things that need the cart to be loaded */
    xCartLoaded(state) {
      return !!state.cartLoaded
    },

    /**
     * Returns the shopping cart loaded from the server
     */
    xShoppingCart(state) {
      return state.cartData || null
    },

    xShoppingCartCoupons(state) {
      return state?.cartData?.coupons || []
    },

    /**
     * Returns a list of loyalty discounts for the logged in user
     */
    xLoyaltyDiscounts(state, getters, rootState, rootGetters) {
      return rootGetters['authuser/xCurrentUser']?.loyalty?.discounts || []
    },

    xLoyaltyDiscountCodes(state, getters) {
      return getters.xLoyaltyDiscounts.map((discount) => discount.discount_code) || []
    },

    xAppliedCoupons(state, getters) {
      return getters.xShoppingCartCoupons.filter((c) => !getters.xLoyaltyDiscountCodes.includes(c.coupon))
    },

    xAppliedCouponList(state, getters) {
      const codes = getters.xAppliedCoupons.map((c) => c.coupon)
      return codes.length ? arrToList(codes) : null
    },

    xTotalDiscount(state, getters) {
      const total = getters.xShoppingCartCoupons.reduce((total, coupon) => {
        return total + parseFloat(coupon.saving)
      }, 0)
      return _money(total / 100)
    },

    /**
     * Returns the amount of items in the users cart. If a user is buying multiple of the same product, each of those
     * product will be counted.
     * Example
     * Apples: Quantity 3,  Oranges: quantity 2, Bananas quantity: 6
     * xTotalCartQuantity will return 11
     */
    xTotalCartQuantity(state, getters, rootState, rootGetters) {
      let ret = 0

      // @todo: map is not needed here.
      // eslint-disable-next-line array-callback-return
      getters?.xShoppingCart?.items?.map((item) => {
        ret += item?.quantity?.value || 0
      })

      return ret
    }
  },

  actions: {
    async xGetCartCount(context) {
      try {
        const url = await MakeCartURL(context, `/wp-json/cocart/v2/cart/items/count`)
        const { status, data } = await context.dispatch('http/noAuthGet', { url, data: {} }, { root: true })
        return status === 200 ? data || 0 : 0
      } catch (e) {
        console.error('Error getting cart count', e)
        return 0
      }
    },
    /**
     * Fetches user's shopping cart from server
     */
    async xFetchCart(context, useKey = false) {
      const fulfillmentType = context.rootGetters['user-region/xDeliverySelected'] ? 'delivery' : 'pickup'
      const selectedLocationId = context.rootGetters['user-region/xSelectedLocationId']
      const selectedAddress = context.rootGetters['user-region/xSelectedAddress']
      const xGetRegions = context.rootGetters['regions/xGetRegions']

      let url = await MakeCartURL(context, `/wp-json/cocart/v2/cart`, useKey)

      url += `&fulfillment_type=${fulfillmentType}`
      url += `&location_id=${selectedLocationId}`

      if (fulfillmentType === 'delivery' && selectedAddress) {
        url += `&region_name=${encodeURIComponent(selectedAddress.region)}`

        const regions = xGetRegions(selectedLocationId)

        if (Array.isArray(regions) && regions.length > 0) {
          const selectedRegion = regions.find((region) => region.name === selectedAddress.region)

          if (selectedRegion) {
            url += `&region_id=${selectedRegion.id}`
          }
        }
      }

      try {
        const response = await context.dispatch('http/noAuthGet', { url, data: {} }, { root: true })
        // console.log("fetch cart received response", response);

        if (response?.data && !Array.isArray(response.data)) {
          context.commit('FETCHED_CART', response.data)
          return response.data
        }

        /** we weren't provided a cart so just make a blank one */
        // console.log("fetch cart making default shopping cart");
        context.commit('NEW_SHOPPING_CART')
      } catch (e) {
        // console.warn("something happened to cart", e);
      }
    },

    /**
     * Add a product to the shopping cart
     * @param product the product we want to buy
     * @param quantity the quantity we want to buy
     */
    async xAddToCart(context, { productID, quantity }) {
      console.log('xAddToCart called', productID, quantity)
      const url = await MakeCartURL(context, `/wp-json/cocart/v2/cart/add-item/`)

      const retval = {
        success: true,
        message: ''
      }

      const payload = { id: '' + productID, quantity: '' + quantity }
      await console.log('sending', url, payload)
      const response = await context
        .dispatch('http/noAuthPost', { url, data: payload }, { root: true })
        .catch((e, b) => {
          console.error(e.response, b)
          retval.success = false
          retval.message = e?.response?.data?.message || 'An Unknown Error Occurred. Please Try Again Later'
          context.dispatch(
            'notifications/xAddErrorMessage',
            {
              message: retval.message,
              seconds: 5,
              type: 'general'
            },
            { root: true }
          )
        })

      console.log('add cart response', response)

      if (response.status === 200) {
        const product = response.data.items.find((product) => {
          return Number(product.id) === Number(productID)
        })

        if (product) {
          // Use map() to transform the array of category objects into an array of names
          const categoryNames = product.categories.map((category) => category.name)
          const concatenatedCategoryNames = categoryNames.join(', ')

          const item = {
            ProductName: product.name,
            ProductID: product.id,
            SKU: product.meta.sku,
            Categories: concatenatedCategoryNames,
            ImageURL: product.featured_image,
            URL: product.permalink
          }
          klaviyo.track('Added To Cart', item)

          try {
            gtag('event', 'add_to_cart', {
              event_category: 'ecommerce',
              event_action: 'add',
              event_label: 'Add to Cart',
              value: product.price,
              currency: 'USD',
              items: [
                {
                  item_id: product.id,
                  item_name: product.name,
                  item_category: concatenatedCategoryNames
                }
              ]
            })
          } catch (e) {
            console.error('Error tracking add to cart event')
            console.error(e)
          }
        }
      }

      if (response) {
        await context.dispatch('xFetchCart')
      }

      return retval
    },

    /**
     * Clear all products from shopping cart
     * @param product the product we want to buy
     * @param quantity the quantity we want to buy
     */
    async xClearCart(context) {
      console.log('xClearCart called')
      const url = await MakeCartURL(context, `/wp-json/cocart/v2/cart/clear`)

      const payload = {}
      await console.log('sending', url, payload)
      const response = await context.dispatch('http/noAuthPost', { url, data: payload }, { root: true })

      console.log('x clear cart got response', response)
      if (response) {
        await context.dispatch('xFetchCart')
      }
    },

    async xRemoveFromCart(context, product) {
      const url = await MakeCartURL(context, `/wp-json/cocart/v2/cart/item/${product.item_key}/`)

      await context.dispatch('http/noAuthDelete', { url }, { root: true })
      await context.dispatch('xFetchCart')

      // Check if the cart is empty after removing the item
      if (context.getters.xTotalCartQuantity === 0) {
        await context.dispatch('xRegenerateCartKey')
      }
    },

    async xRegenerateCartKey(context) {
      // Force creation of a new cart key
      const newCartKey = await FindCartKey(context, null, null, true)
      console.log('Regenerated cart key', newCartKey)

      if (newCartKey) {
        // Fetch the cart again with the new key
        const newCartUrl = await MakeCartURL(context, `/wp-json/cocart/v2/cart`, true)
        await context.dispatch('xFetchCart', true)
      } else {
        console.error('Failed to generate new cart key')
      }
    },

    /**
     * Add a product to the shopping cart
     * @param itemKey the uuid of the product
     * @param quantity the updated quantity
     */
    async xUpdateProductQuantity(context, { itemKey, quantity }) {
      console.log('xUpdateProductQuantity called', itemKey, quantity)

      const url = await MakeCartURL(context, `/wp-json/cocart/v2/cart/item/${itemKey}`)
      const data = { quantity: '' + quantity }

      await context.dispatch('http/noAuthPost', { url, data }, { root: true })
      await context.dispatch('xFetchCart')
    },

    /**
     * Add a coupon to the user's shopping cart
     * @param context
     * @param coupon the coupon code to add
     * @param refresh if true we refresh the shopping cart after applying coupon
     */
    async xAddCoupon(context, coupon, refresh = true) {
      const url = await MakeCartURL(context, '/wp-json/cocart/v1/coupon')
      const data = { coupon: coupon.discount_code }

      await context.dispatch('http/noAuthPost', { url, data }, { root: true })

      if (refresh) await context.dispatch('xFetchCart')
    },

    /**
     * Removes a coupon from the user's shopping cart
     * @param context
     * @param coupon the coupon code to add
     * @param refresh if true we refresh the shopping cart after applying coupon
     */
    async xRemoveCoupon(context, coupon, refresh = true) {
      const url = await MakeCartURL(context, '/wp-json/cocart/v1/coupon')
      const data = { coupon: coupon.discount_code }

      await context.dispatch('http/noAuthDelete', { url, data }, { root: true })

      if (refresh) await context.dispatch('xFetchCart')
    },

    /**
     * Remove multiple coupons from the cart.
     * @param context
     * @param codes Array of coupon codes to remove. If not provided, all coupons will be removed.
     * @param refresh Refresh the shopping cart after removal.
     */
    async xRemoveAllCoupons(context, codes = [], refresh = true) {
      const couponsToRemove = !codes.length
        ? context.getters.xShoppingCartCoupons.map((coupon) => coupon.coupon)
        : codes

      if (!couponsToRemove.length) return

      const removalRequests = couponsToRemove.map((code) => context.dispatch('xRemoveCoupon', { discount_code: code }))
      await Promise.all(removalRequests)

      if (refresh) await context.dispatch('xFetchCart')
    },

    async xVerifyCoupon(context, coupon) {
      const currentCouponCodes = context.getters.xShoppingCartCoupons.map((coupon) => coupon.coupon)
      return await context.dispatch(
        'http/post',
        {
          url: '/wp-json/salve/v1/verify_coupon',
          data: {
            coupon: coupon.promoCode,
            couponType: coupon.promoType,
            currentCouponCodes
          }
        },
        { root: true }
      )
    },

    async xPurchaseCart(context, checkout) {
      const retval = { success: true, msg: '', order: undefined }

      try {
        const response = await context.dispatch(
          'http/post',
          {
            url: '/wp-json/salve/v1/order',
            data: checkout
          },
          { root: true }
        )

        retval.order = response?.data?.order
        if (response.status === 200) {
          try {
            gtag('event', 'purchase', {
              event_category: 'ecommerce',
              event_action: 'purchase',
              event_label: 'Purchase Completed',
              value: response?.data?.order?.total,
              currency: 'USD',
              transaction_id: response?.data?.order?.id
            })
          } catch (e) {
            console.error('Error tracking purchase event')
            console.error(e)
          }
        }
      } catch (error) {
        console.error('Error during purchase cart', { ...error })
        retval.success = false
        const d = 'There was an issue submitting the form. Please resolve the errors below and try again'
        const msg = error?.response?.data?.message || d
        retval.msg = msg
        await context.dispatch(
          'notifications/xAddErrorMessage',
          {
            message: msg,
            seconds: 5,
            type: 'general'
          },
          { root: true }
        )
      }

      return retval
    }
  },

  mutations: {
    /**
     * Saves the cart data to internal module state
     */
    FETCHED_CART(state, cart) {
      if (cart) {
        /* Convert totals from strings to numbers */
        const newCart = { ...cart }
        Object.keys(newCart.totals).forEach((key) => {
          newCart.totals[key] = parseFloat(newCart.totals[key]) / 100
        })
        state.cartData = newCart
      }

      state.cartLoaded = true
    },

    /**
     * Creates a blank shopping cart
     */
    NEW_SHOPPING_CART(state) {
      state.cartData = CreateEmptyShoppingCart()
    },

    /**
     * Sets cartKey variable in module state
     */
    SET_CART_KEY(state, key) {
      // console.log("SET CART KEY");
      state.cartKey = key
    },

    /**
     * Sets cart key in local storage
     */
    LOCALSTORAGE_SET_CART_KEY(state, key) {
      // console.log("SET CART KEY LOCALSTORAGE");
      localStorage.setItem(LOCAL_STORAGE_CART_KEY, key)
    }
  }
}
