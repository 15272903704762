export const reformatQuery = (obj, encode = true) => {
  if (typeof obj !== 'object') {
    return ''
  }

  return (
    '?' +
    Object.keys(obj)
      .filter((item) => !!obj[item])
      .map((item) => `${item}=${encode ? encodeURIComponent(obj[item]) : obj[item]}`)
      .join('&')
  )
}

export const loverCaseFirstLetter = (string) =>
  typeof string === 'string' ? string.charAt(0).toLowerCase() + string.slice(1) : ''
