/* eslint-disable */

export default {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {
    /**
     * Fetches social metadata for the provided product
     */
    xFetchProductMeta(context, product) {
      const retval = []
      Object.values(product?.yoast_meta || []).forEach((meta) => {
        if (meta?.property && meta?.content) {
          retval.push(meta)
        } else {
          console.error('Invalid meta tag', meta)
        }
      })
      return retval
    },

    async xFetchByType(context, { type, locationId }) {
      let url = `/wp-json/salve/v1/product_by_type?type=${type}&location_id=${locationId}`

      try {
        const response = await context.dispatch('http/get', { url, data: { type } }, { root: true })
        // console.log("xFetchByType received response", response.data);
        return response?.data
      } catch (e) {
        // console.warn("something happened to xFetchByType", e);
      }
    }
  }
}
