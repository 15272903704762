/**
 * Fetches cart_key from localstorage. Returns null if value doesn't exist
 */

import { LOCAL_STORAGE_CART_KEY } from '@/store/cart/config'

export default function GetCartKeyFromLocalStorage() {
  if (process.server) return null
  return localStorage.getItem(LOCAL_STORAGE_CART_KEY)
}
