<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ClientOnly>
      <UpdateManager />
    </ClientOnly>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  const store = useStore()
  await Promise.all([store.dispatch('xFetchNavigationData'), store.dispatch('regions/xFetchLocations')])
</script>

<script>
  export default {}
</script>
