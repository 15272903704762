import FindKey from './FindCartKey'

/**
 * Adds a cart_key query param to provided URL if the user is not logged in
 * @param context vuex context variable
 * @param url a valid URL string
 * @param GetCartKey a function that will find and return a valid cart key
 * @returns {string}
 */
export default async function MakeCartURL(context, url, forceKey = false, FindCartKey = FindKey) {
  // console.log("makeCartURL called");

  if (forceKey) {
    // console.log("MakeCartURL cart is forcing cart key ");
  }

  // Cart no longer uses auth instead always requires a cart_key now
  const isLoggedIn = false
  // const isLoggedIn = context.rootGetters["auth/xHasAccessToken"];
  // console.log("MakeCartURL cart checking for acces token: ", isLoggedIn);

  if (!isLoggedIn || forceKey) {
    // console.log("adding cart key to fetch", isLoggedIn, forceKey);
    const key = await FindCartKey(context)
    url += `?cart_key=${key}`
  }

  // console.log("makeCartURL returning", url);
  return url
}
