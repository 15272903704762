/* eslint-disable */

export default {
  namespaced: true,

  state: () => ({}),

  getters: {},

  actions: {
    /**
     * Fetches blog posts in newest to oldest order
     * @param context vuex context variable
     * @param numPosts how many posts we want to fetch.
     * @returns {*[]}
     */
    async xFetchBlogPosts(context, numPosts = 10) {
      let url = `/wp-json/salve/v1/posts?page=1&per_page=${numPosts}`

      try {
        const response = await context.dispatch('http/get', { url, data: {} }, { root: true })
        // console.log("xFetchBlogPosts received response", response.data.posts.length, response.data);
        return response.data
      } catch (e) {
        // console.warn("something happened to xFetchBlogPosts", e);
      }
    }
  },

  mutations: {}
}
