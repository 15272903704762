// plugins/schema.js

import { defineNuxtPlugin, useState } from '#app'
import { createWebsiteSchema, createSchemaScript } from '~/utils/schema'

export default defineNuxtPlugin((nuxtApp) => {
  // Initialize state if not already set
  const storeName = useState('storeName', () => 'The Flowery')
  const storeUrl = useState('storeUrl', () => 'https://theflowery.co')
  const storeDescription = useState(
    'storeDescription',
    () => 'Premium cannabis products for Florida medical marijuana patients'
  )

  const websiteSchema = createWebsiteSchema()

  useHead({
    script: [createSchemaScript(websiteSchema)]
  })
})
