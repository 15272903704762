import { HomeRequests } from './requests/home.requests'
import { HeaderRequests } from './requests/header.requests'
import { AuthRequests } from './requests/auth.requests'
import { ProfileRequests } from './requests/profile.request'
import { ShopPlatformRequests } from './requests/shop.platform-request'
import { UserRequests } from './requests/user.requests'
import { RegionRequests } from './requests/region.requests'
import { ProductRequests } from './requests/product.requests'
import { CartRequests } from './requests/cart.request'
import { BlogRequests } from './requests/blog.requests'
import { PageRequests } from './requests/page.requests'
import { InternalRequests } from './requests/iapi.request'
import { ContactRequests } from './requests/contact.request'

export const $api = {
  HomeRequests: new HomeRequests(),
  HeaderRequests: new HeaderRequests(),
  AuthRequests: new AuthRequests(),
  ShopPlatformRequests: new ShopPlatformRequests(),
  ProfileRequests: new ProfileRequests(),
  UserRequests: new UserRequests(),
  RegionRequests: new RegionRequests(),
  ProductRequests: new ProductRequests(),
  CartRequests: new CartRequests(),
  BlogRequests: new BlogRequests(),
  PageRequests: new PageRequests(),
  InternalRequests: new InternalRequests(),
  ContactRequests: new ContactRequests()
}
