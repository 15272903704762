import orders from './orders/orders'
export default {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {},
  mutations: {},

  modules: {
    orders
  }
}
