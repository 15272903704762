export default {
  namespaced: true,

  state: () => ({
    // A list of messages to display
    messages: {},

    // Used to only show the welcome message once
    hasShownLoginMessage: false,

    // Total number of messages that have been displayed
    numMessages: 0
  }),

  getters: {
    /**
     * Returns all notifications marked as general
     * @param state
     * @returns {unknown[]}
     */
    xNotifications(state) {
      return Object.values(state.messages).filter((msg) => {
        return msg.type === 'general'
      })
    },

    /**
     * Retrurn notifications with a different type
     * @param String type the type of notification you want
     */
    xNotificationsByType(state) {
      return (type = 'general') => {
        return Object.values(state.messages).filter((msg) => {
          return msg.type === type
        })
      }
    }
  },

  actions: {
    /**
     * Add a notification to display on the screen
     */
    xAddNotification(context, { message, color = '#4899B9', seconds = 10, type = 'general' }) {
      console.log('x add notifcation called', message, color, seconds)
      console.log('seconds', seconds)

      const index = context.state.numMessages + 1
      context.commit('ADD_MESSAGE', { message, color, seconds, index, type })
      console.log('xAddNotification added this', index)

      if (seconds > 0) {
        setTimeout(() => {
          console.log('removinmg', message, index)
          context.commit('REMOVE_MESSAGE', index)
        }, seconds * 1000)
      }
    },

    xClearAllNotifications(context) {
      context.commit('CLEAR_MESSAGES')
    },

    xAddErrorMessage(context, { message, seconds = 10, type = 'general' }) {
      context.dispatch('xAddNotification', {
        message,
        color: '#F3B6D0',
        seconds,
        type
      })
    },

    xAddInfoMessage(context, { message, seconds = 10, type = 'general' }) {
      context.dispatch('xAddNotification', { message, seconds, type })
    },

    xAddWarningMessage(context, { message, seconds = 10, type = 'general' }) {
      context.dispatch('xAddNotification', {
        message,
        color: 'yellow',
        seconds,
        type
      })
    }
  },

  mutations: {
    /**
     * Clear all notifications
     * @param state
     * @constructor
     */
    CLEAR_MESSAGES(state) {
      console.log('Clear all notifications')
      state.messages = {}
    },

    /**
     * Add a notification to display for the user
     * @param state
     * @param promise
     * @constructor
     */
    ADD_MESSAGE(state, messageObject) {
      state.messages[messageObject.index] = messageObject
      state.numMessages++
      console.log('Added message', messageObject.index, { ...state.messages })

      state.messages = { ...state.messages }
    },

    /**
     * Removes the message at at the specified index
     * @param state
     * @param promise
     * @constructor
     */
    REMOVE_MESSAGE(state, index) {
      console.log('REM MSG', index)
      if (state?.messages[index]) {
        delete state.messages[index]
        // only keep non null messages
        // state.messages = state.messages.filter(x => x);
        state.messages = { ...state.messages }
      }
    }
  }
}
