export default {
  SET_HEADER_DATA: (state, payload) => {
    state.headerData = payload
  },

  /**
   * Sets the status of the mobile navbar. If true navbar is open, if false navbar closed
   * @param status {boolean} pass true to open navbar, false to close navbar
   */
  IS_NAVBAR_OPEN(state, status = false) {
    state.mobileNavbarOpen = status
  },

  TOGGLE_NAVBAR(state) {
    state.mobileNavbarOpen = !state.mobileNavbarOpen
  }
}
