import { ENUM_END_POINT } from '@/utils/api/const'
import { ApiRequest } from '@/utils/api/api.request'
import { requestPipe } from '@/utils/api/api-pipes'
import { homePipe } from '@/utils/api/pipes/home-pipes'

export class HomeRequests extends ApiRequest {
  getHomeData() {
    return this.get(`${ENUM_END_POINT.HOME}`).then(requestPipe).then(homePipe)
  }
}
