/* eslint-disable */
import environment from '../../environment'

export default {
  /**
   * Fetches navigation data that will be displayed in the navbar and footer
   */
  async xFetchNavigationData({ state, dispatch, commit }) {
    const url = environment.STORE_ROUTES.NAVIGATION_DATA
    const data = {}

    try {
      const response = await dispatch('http/get', { url, data }, { root: true })
      commit('SET_HEADER_DATA', response.data.acf)
      return response.data
    } catch (error) {
      console.error('could not fetch header/footer data', error)
    }
  },

  /**
   * Opens the mobile navbar
   */
  xOpenMobileNavbar({ commit }) {
    commit('IS_NAVBAR_OPEN', true)
  },

  /**
   * Closes the mobile navbar
   */
  xCloseMobileNavbar({ commit }) {
    commit('IS_NAVBAR_OPEN', false)
  },

  /**
   * Toggles mobile navbar
   */
  xToggleMobileNavbar({ commit }) {
    commit('TOGGLE_NAVBAR')
  }
}
