import { useRoute } from '#imports'
import { watch } from 'vue'

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute()
  const insertScript = () => {
    if (window.yotpo) return // If Yotpo is already defined, do not re-insert the script

    const script = document.createElement('script')
    script.src = 'https://cdn-widgetsrepository.yotpo.com/v1/loader/XzU19j8cckAIdlYguN25EmC2U47BNfg1cBp9EzWi'
    script.async = true
    script.onload = () => {
      ensureYotpoWidgetsReady() // Try to initialize Yotpo widgets after script is loaded
    }
    document.head.appendChild(script)
  }

  const ensureYotpoWidgetsReady = (attempts = 0) => {
    if (window.yotpoWidgetsContainer && typeof window.yotpoWidgetsContainer.initWidgets === 'function') {
      window.yotpoWidgetsContainer.initWidgets()
    } else if (attempts < 10) {
      // Retry up to 10 times if not ready
      setTimeout(() => ensureYotpoWidgetsReady(attempts + 1), 300) // Wait 300ms before retrying
    } else {
      console.error('Failed to initialize Yotpo widgets after multiple attempts')
    }
  }

  // Watch for route changes
  watch(
    () => route.path,
    (newPath, oldPath) => {
      ensureYotpoWidgetsReady() // Initialize or reinitialize Yotpo widgets on route change
    }
  )

  nuxtApp.hook('app:mounted', insertScript)

  nuxtApp.provide('ensureYotpoWidgetsReady', ensureYotpoWidgetsReady)
})
