<script>
  import { $api } from '@/utils/api'

  const longPollInterval = 60 * 1000 // 1 min
  const protectedRoutes = ['registration', 'cart', 'checkout']

  let timeoutId = null

  export default {
    mounted() {
      timeoutId = setTimeout(this.poll, longPollInterval)
    },
    beforeUnmount() {
      if (timeoutId) clearTimeout(timeoutId)
    },
    methods: {
      async poll() {
        try {
          const { buildId, updateStrategy, maintenanceMode } = await $api.InternalRequests.getMeta()
          if (buildId && buildId !== this.$config.public.buildId) {
            this.handleUpdate(updateStrategy)
          }
          if (maintenanceMode) {
            this.enterMaintenanceMode()
          }
        } catch {
          /* silently ignore errors */
        }
        timeoutId = setTimeout(this.poll, longPollInterval)
      },
      handleUpdate(updateStrategy) {
        switch (updateStrategy) {
          case 'URGENT':
            location.reload()
            break
          case 'PROMPT': // @Todo: show prompt to user and await/pause until they confirm.
            break
          default:
            if (!protectedRoutes.includes(this.$route.name)) {
              location.reload()
            }
            break
        }
      },
      enterMaintenanceMode() {
        location.reload()
      }
    },
    render: () => null
  }
</script>
