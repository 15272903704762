import { default as aboutRxv5VSSNOlMeta } from "/home/forge/theflowery.co/pages/about.vue?macro=true";
import { default as accountR2K1uuBLdDMeta } from "/home/forge/theflowery.co/pages/account.vue?macro=true";
import { default as _91slug_93hdKSLc71mkMeta } from "/home/forge/theflowery.co/pages/article/[slug].vue?macro=true";
import { default as blogTmBh7xHZHAMeta } from "/home/forge/theflowery.co/pages/blog.vue?macro=true";
import { default as careersjCZWkZS66VMeta } from "/home/forge/theflowery.co/pages/careers.vue?macro=true";
import { default as cartYP0Im2gFqsMeta } from "/home/forge/theflowery.co/pages/cart.vue?macro=true";
import { default as checkoutIxcRXx3Zu4Meta } from "/home/forge/theflowery.co/pages/checkout.vue?macro=true";
import { default as contactUNxrJandoYMeta } from "/home/forge/theflowery.co/pages/contact.vue?macro=true";
import { default as delivery_45regions_45coverage3C2jF3lVhKMeta } from "/home/forge/theflowery.co/pages/delivery-regions-coverage.vue?macro=true";
import { default as doctorsxPRdGyKwIVMeta } from "/home/forge/theflowery.co/pages/doctors.vue?macro=true";
import { default as eulaRm6OAW4nnGMeta } from "/home/forge/theflowery.co/pages/eula.vue?macro=true";
import { default as flowery_45categoriesK0eyqgBSkwMeta } from "/home/forge/theflowery.co/pages/flowery-categories.vue?macro=true";
import { default as indexyNBmgvBYxKMeta } from "/home/forge/theflowery.co/pages/index.vue?macro=true";
import { default as locationsAa0mYj57QSMeta } from "/home/forge/theflowery.co/pages/locations.vue?macro=true";
import { default as loyalty_45programgaeRCv62fAMeta } from "/home/forge/theflowery.co/pages/loyalty-program.vue?macro=true";
import { default as mediaFq7AX94ndaMeta } from "/home/forge/theflowery.co/pages/media.vue?macro=true";
import { default as privacy_45policy69o5dAESs3Meta } from "/home/forge/theflowery.co/pages/privacy-policy.vue?macro=true";
import { default as _91id_93KDAeLg1IsZMeta } from "/home/forge/theflowery.co/pages/product/[id].vue?macro=true";
import { default as account_45detailsYpduYXdD7sMeta } from "/home/forge/theflowery.co/pages/profile/account-details.vue?macro=true";
import { default as addressOO1qGkgYT0Meta } from "/home/forge/theflowery.co/pages/profile/address.vue?macro=true";
import { default as dashboards0IhvHN2cKMeta } from "/home/forge/theflowery.co/pages/profile/dashboard.vue?macro=true";
import { default as loyaltyjMC0A9nHRyMeta } from "/home/forge/theflowery.co/pages/profile/loyalty.vue?macro=true";
import { default as medicalxzN8ShooTgMeta } from "/home/forge/theflowery.co/pages/profile/medical.vue?macro=true";
import { default as _91id_93RZSccu3O1pMeta } from "/home/forge/theflowery.co/pages/profile/order/[id].vue?macro=true";
import { default as orderspjkIRYULG4Meta } from "/home/forge/theflowery.co/pages/profile/orders.vue?macro=true";
import { default as paymentsux0hWRQPntMeta } from "/home/forge/theflowery.co/pages/profile/payments.vue?macro=true";
import { default as registrationrS3RKzM385Meta } from "/home/forge/theflowery.co/pages/registration.vue?macro=true";
import { default as resourcesySWq6oPn8iMeta } from "/home/forge/theflowery.co/pages/resources.vue?macro=true";
import { default as _91_91slug_93_93aXJsHuaV4rMeta } from "/home/forge/theflowery.co/pages/shop/[[slug]].vue?macro=true";
import { default as support_45appO0IQ7wns8VMeta } from "/home/forge/theflowery.co/pages/support-app.vue?macro=true";
import { default as termsLT7W8JOepAMeta } from "/home/forge/theflowery.co/pages/terms.vue?macro=true";
import { default as verifylGBFQHIeyEMeta } from "/home/forge/theflowery.co/pages/verify.vue?macro=true";
export default [
  {
    name: aboutRxv5VSSNOlMeta?.name ?? "about",
    path: aboutRxv5VSSNOlMeta?.path ?? "/about",
    meta: aboutRxv5VSSNOlMeta || {},
    alias: aboutRxv5VSSNOlMeta?.alias || [],
    redirect: aboutRxv5VSSNOlMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/about.vue").then(m => m.default || m)
  },
  {
    name: accountR2K1uuBLdDMeta?.name ?? "account",
    path: accountR2K1uuBLdDMeta?.path ?? "/account",
    meta: accountR2K1uuBLdDMeta || {},
    alias: accountR2K1uuBLdDMeta?.alias || [],
    redirect: accountR2K1uuBLdDMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hdKSLc71mkMeta?.name ?? "article-slug",
    path: _91slug_93hdKSLc71mkMeta?.path ?? "/article/:slug",
    meta: _91slug_93hdKSLc71mkMeta || {},
    alias: _91slug_93hdKSLc71mkMeta?.alias || [],
    redirect: _91slug_93hdKSLc71mkMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: blogTmBh7xHZHAMeta?.name ?? "blog",
    path: blogTmBh7xHZHAMeta?.path ?? "/blog",
    meta: blogTmBh7xHZHAMeta || {},
    alias: blogTmBh7xHZHAMeta?.alias || [],
    redirect: blogTmBh7xHZHAMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: careersjCZWkZS66VMeta?.name ?? "careers",
    path: careersjCZWkZS66VMeta?.path ?? "/careers",
    meta: careersjCZWkZS66VMeta || {},
    alias: careersjCZWkZS66VMeta?.alias || [],
    redirect: careersjCZWkZS66VMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: cartYP0Im2gFqsMeta?.name ?? "cart",
    path: cartYP0Im2gFqsMeta?.path ?? "/cart",
    meta: cartYP0Im2gFqsMeta || {},
    alias: cartYP0Im2gFqsMeta?.alias || [],
    redirect: cartYP0Im2gFqsMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutIxcRXx3Zu4Meta?.name ?? "checkout",
    path: checkoutIxcRXx3Zu4Meta?.path ?? "/checkout",
    meta: checkoutIxcRXx3Zu4Meta || {},
    alias: checkoutIxcRXx3Zu4Meta?.alias || [],
    redirect: checkoutIxcRXx3Zu4Meta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: contactUNxrJandoYMeta?.name ?? "contact",
    path: contactUNxrJandoYMeta?.path ?? "/contact",
    meta: contactUNxrJandoYMeta || {},
    alias: contactUNxrJandoYMeta?.alias || [],
    redirect: contactUNxrJandoYMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: delivery_45regions_45coverage3C2jF3lVhKMeta?.name ?? "delivery-regions-coverage",
    path: delivery_45regions_45coverage3C2jF3lVhKMeta?.path ?? "/delivery-regions-coverage",
    meta: delivery_45regions_45coverage3C2jF3lVhKMeta || {},
    alias: delivery_45regions_45coverage3C2jF3lVhKMeta?.alias || [],
    redirect: delivery_45regions_45coverage3C2jF3lVhKMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/delivery-regions-coverage.vue").then(m => m.default || m)
  },
  {
    name: doctorsxPRdGyKwIVMeta?.name ?? "doctors",
    path: doctorsxPRdGyKwIVMeta?.path ?? "/doctors",
    meta: doctorsxPRdGyKwIVMeta || {},
    alias: doctorsxPRdGyKwIVMeta?.alias || [],
    redirect: doctorsxPRdGyKwIVMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/doctors.vue").then(m => m.default || m)
  },
  {
    name: eulaRm6OAW4nnGMeta?.name ?? "eula",
    path: eulaRm6OAW4nnGMeta?.path ?? "/eula",
    meta: eulaRm6OAW4nnGMeta || {},
    alias: eulaRm6OAW4nnGMeta?.alias || [],
    redirect: eulaRm6OAW4nnGMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/eula.vue").then(m => m.default || m)
  },
  {
    name: flowery_45categoriesK0eyqgBSkwMeta?.name ?? "flowery-categories",
    path: flowery_45categoriesK0eyqgBSkwMeta?.path ?? "/flowery-categories",
    meta: flowery_45categoriesK0eyqgBSkwMeta || {},
    alias: flowery_45categoriesK0eyqgBSkwMeta?.alias || [],
    redirect: flowery_45categoriesK0eyqgBSkwMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/flowery-categories.vue").then(m => m.default || m)
  },
  {
    name: indexyNBmgvBYxKMeta?.name ?? "index",
    path: indexyNBmgvBYxKMeta?.path ?? "/",
    meta: indexyNBmgvBYxKMeta || {},
    alias: indexyNBmgvBYxKMeta?.alias || [],
    redirect: indexyNBmgvBYxKMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/index.vue").then(m => m.default || m)
  },
  {
    name: locationsAa0mYj57QSMeta?.name ?? "locations",
    path: locationsAa0mYj57QSMeta?.path ?? "/locations",
    meta: locationsAa0mYj57QSMeta || {},
    alias: locationsAa0mYj57QSMeta?.alias || [],
    redirect: locationsAa0mYj57QSMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/locations.vue").then(m => m.default || m)
  },
  {
    name: loyalty_45programgaeRCv62fAMeta?.name ?? "loyalty-program",
    path: loyalty_45programgaeRCv62fAMeta?.path ?? "/loyalty-program",
    meta: loyalty_45programgaeRCv62fAMeta || {},
    alias: loyalty_45programgaeRCv62fAMeta?.alias || [],
    redirect: loyalty_45programgaeRCv62fAMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/loyalty-program.vue").then(m => m.default || m)
  },
  {
    name: mediaFq7AX94ndaMeta?.name ?? "media",
    path: mediaFq7AX94ndaMeta?.path ?? "/media",
    meta: mediaFq7AX94ndaMeta || {},
    alias: mediaFq7AX94ndaMeta?.alias || [],
    redirect: mediaFq7AX94ndaMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/media.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy69o5dAESs3Meta?.name ?? "privacy-policy",
    path: privacy_45policy69o5dAESs3Meta?.path ?? "/privacy-policy",
    meta: privacy_45policy69o5dAESs3Meta || {},
    alias: privacy_45policy69o5dAESs3Meta?.alias || [],
    redirect: privacy_45policy69o5dAESs3Meta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KDAeLg1IsZMeta?.name ?? "product-id",
    path: _91id_93KDAeLg1IsZMeta?.path ?? "/product/:id",
    meta: _91id_93KDAeLg1IsZMeta || {},
    alias: _91id_93KDAeLg1IsZMeta?.alias || [],
    redirect: _91id_93KDAeLg1IsZMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: account_45detailsYpduYXdD7sMeta?.name ?? "profile-account-details",
    path: account_45detailsYpduYXdD7sMeta?.path ?? "/profile/account-details",
    meta: account_45detailsYpduYXdD7sMeta || {},
    alias: account_45detailsYpduYXdD7sMeta?.alias || [],
    redirect: account_45detailsYpduYXdD7sMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/profile/account-details.vue").then(m => m.default || m)
  },
  {
    name: addressOO1qGkgYT0Meta?.name ?? "profile-address",
    path: addressOO1qGkgYT0Meta?.path ?? "/profile/address",
    meta: addressOO1qGkgYT0Meta || {},
    alias: addressOO1qGkgYT0Meta?.alias || [],
    redirect: addressOO1qGkgYT0Meta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/profile/address.vue").then(m => m.default || m)
  },
  {
    name: dashboards0IhvHN2cKMeta?.name ?? "profile-dashboard",
    path: dashboards0IhvHN2cKMeta?.path ?? "/profile/dashboard",
    meta: dashboards0IhvHN2cKMeta || {},
    alias: dashboards0IhvHN2cKMeta?.alias || [],
    redirect: dashboards0IhvHN2cKMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/profile/dashboard.vue").then(m => m.default || m)
  },
  {
    name: loyaltyjMC0A9nHRyMeta?.name ?? "profile-loyalty",
    path: loyaltyjMC0A9nHRyMeta?.path ?? "/profile/loyalty",
    meta: loyaltyjMC0A9nHRyMeta || {},
    alias: loyaltyjMC0A9nHRyMeta?.alias || [],
    redirect: loyaltyjMC0A9nHRyMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/profile/loyalty.vue").then(m => m.default || m)
  },
  {
    name: medicalxzN8ShooTgMeta?.name ?? "profile-medical",
    path: medicalxzN8ShooTgMeta?.path ?? "/profile/medical",
    meta: medicalxzN8ShooTgMeta || {},
    alias: medicalxzN8ShooTgMeta?.alias || [],
    redirect: medicalxzN8ShooTgMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/profile/medical.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RZSccu3O1pMeta?.name ?? "profile-order-id",
    path: _91id_93RZSccu3O1pMeta?.path ?? "/profile/order/:id",
    meta: _91id_93RZSccu3O1pMeta || {},
    alias: _91id_93RZSccu3O1pMeta?.alias || [],
    redirect: _91id_93RZSccu3O1pMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/profile/order/[id].vue").then(m => m.default || m)
  },
  {
    name: orderspjkIRYULG4Meta?.name ?? "profile-orders",
    path: orderspjkIRYULG4Meta?.path ?? "/profile/orders",
    meta: orderspjkIRYULG4Meta || {},
    alias: orderspjkIRYULG4Meta?.alias || [],
    redirect: orderspjkIRYULG4Meta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/profile/orders.vue").then(m => m.default || m)
  },
  {
    name: paymentsux0hWRQPntMeta?.name ?? "profile-payments",
    path: paymentsux0hWRQPntMeta?.path ?? "/profile/payments",
    meta: paymentsux0hWRQPntMeta || {},
    alias: paymentsux0hWRQPntMeta?.alias || [],
    redirect: paymentsux0hWRQPntMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/profile/payments.vue").then(m => m.default || m)
  },
  {
    name: registrationrS3RKzM385Meta?.name ?? "registration",
    path: registrationrS3RKzM385Meta?.path ?? "/registration",
    meta: registrationrS3RKzM385Meta || {},
    alias: registrationrS3RKzM385Meta?.alias || [],
    redirect: registrationrS3RKzM385Meta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: resourcesySWq6oPn8iMeta?.name ?? "resources",
    path: resourcesySWq6oPn8iMeta?.path ?? "/resources",
    meta: resourcesySWq6oPn8iMeta || {},
    alias: resourcesySWq6oPn8iMeta?.alias || [],
    redirect: resourcesySWq6oPn8iMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/resources.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93aXJsHuaV4rMeta?.name ?? "shop-slug",
    path: _91_91slug_93_93aXJsHuaV4rMeta?.path ?? "/shop/:slug?",
    meta: _91_91slug_93_93aXJsHuaV4rMeta || {},
    alias: _91_91slug_93_93aXJsHuaV4rMeta?.alias || [],
    redirect: _91_91slug_93_93aXJsHuaV4rMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/shop/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: support_45appO0IQ7wns8VMeta?.name ?? "support-app",
    path: support_45appO0IQ7wns8VMeta?.path ?? "/support-app",
    meta: support_45appO0IQ7wns8VMeta || {},
    alias: support_45appO0IQ7wns8VMeta?.alias || [],
    redirect: support_45appO0IQ7wns8VMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/support-app.vue").then(m => m.default || m)
  },
  {
    name: termsLT7W8JOepAMeta?.name ?? "terms",
    path: termsLT7W8JOepAMeta?.path ?? "/terms",
    meta: termsLT7W8JOepAMeta || {},
    alias: termsLT7W8JOepAMeta?.alias || [],
    redirect: termsLT7W8JOepAMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: verifylGBFQHIeyEMeta?.name ?? "verify",
    path: verifylGBFQHIeyEMeta?.path ?? "/verify",
    meta: verifylGBFQHIeyEMeta || {},
    alias: verifylGBFQHIeyEMeta?.alias || [],
    redirect: verifylGBFQHIeyEMeta?.redirect || undefined,
    component: () => import("/home/forge/theflowery.co/pages/verify.vue").then(m => m.default || m)
  }
]