/**
 * This module handles the authenticated user state and server communication for authenticated user
 */
export default {
  namespaced: true,

  state: () => ({
    // If true the animation will be running
    renderFireworks: false
  }),

  getters: {
    xIsRunningFourTwentyFireworks(state) {
      return state.renderFireworks
    }
  },

  actions: {
    xStartFourTwentyFireworks({ commit }) {
      commit('START_FIREWORKS')
    },

    xStopFourTwentyFireworks({ commit }) {
      commit('STOP_FIREWORKS')
    }
  },

  mutations: {
    START_FIREWORKS(state) {
      // console.log("commit start freworks");
      state.renderFireworks = true
    },

    STOP_FIREWORKS(state) {
      // console.log("stopping fireworks");
      state.renderFireworks = false
    }
  },

  // nested modules
  modules: {}
}
