import { defineNuxtPlugin } from '#imports'
import GetAgeVerificationFromLocalStorage from '@/store/auth/utilities/GetAgeVerificationFromLocalStorage'

/**
 * Load site variables for authenticated user
 * @param storeInstance
 * @param token auth token
 */
const loadSiteVariables = async function (storeInstance, token) {
  storeInstance.commit('auth/SET_AUTH_TOKEN', token)

  // We wait for xFetchAuthenticatedUser to finish so that we can send the user id to the get cart function
  await storeInstance.dispatch('authuser/xFetchAuthenticatedUser')

  const user = storeInstance.getters['authuser/xCurrentUser']
  storeInstance.dispatch('cart/xFetchCart')
  storeInstance.commit('auth/SET_STATE_KNOWN', true)
}

/**
 * Load variables for unauthenticated user
 * @param storeInstance
 */
const unauthenticatedLoadSiteVariables = async function (storeInstance) {
  storeInstance.dispatch('cart/xFetchCart')
  storeInstance.commit('auth/SET_STATE_KNOWN', true)
}

export default defineNuxtPlugin((nuxtApp) => {
  const store = nuxtApp.vueApp.config.globalProperties.$store

  nuxtApp.hooks.hook('app:created', () => {
    // Set the age verification flag
    store.commit('auth/SET_IS_ADULT', GetAgeVerificationFromLocalStorage())
    // Check if user is logged in
    const storedToken = process.client ? localStorage.getItem('token') : null
    if (storedToken) {
      loadSiteVariables(store, storedToken)
      return
    }
    unauthenticatedLoadSiteVariables(store)
  })
})
