export default defineNuxtPlugin(() => {
  const linkTypekit = document.createElement('link')
  linkTypekit.rel = 'stylesheet'
  linkTypekit.href = 'https://use.typekit.net/hht0cqi.css'
  document.head.appendChild(linkTypekit)

  const alpine = document.createElement('script')
  alpine.type = 'text/javascript'
  alpine.defer = true
  alpine.src = '/scripts/alpinetrack.js'
  document.head.appendChild(alpine)

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = 'ze-snippet'
  script.defer = true
  script.src = 'https://static.zdassets.com/ekr/snippet.js?key=8a2271ef-dfac-4682-bd5f-236439ce108f'
  document.head.appendChild(script)

  const klaviyoSdk = document.createElement('script')
  klaviyoSdk.type = 'text/javascript'
  klaviyoSdk.defer = true
  klaviyoSdk.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XRJf2n'
  document.head.appendChild(klaviyoSdk)

  const klaviyo = document.createElement('script')
  klaviyo.type = 'text/javascript'
  klaviyo.defer = true
  klaviyo.src = '/scripts/klaviyo.js'
  document.head.appendChild(klaviyo)

  document
    .querySelectorAll('input, textarea')
    .forEach(
      (input) =>
        input.addEventListener('focus', () =>
          document
            .querySelector('meta[name="viewport"]')
            .setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
        ) &&
        input.addEventListener('blur', () =>
          document
            .querySelector('meta[name="viewport"]')
            .setAttribute('content', 'width=device-width, initial-scale=1.0')
        )
    )
})
