/**
 * Updates user data on the server
 * @param context vuex context variable
 * @param type type of update we are doing (address, payments, etc). Only one section is updated at a time
 * @param user the user object
 * @constructor
 */
export default async function UpdateUserData(context, { type, user }) {
  // console.log("Update User Data", type, user);

  const url = '/wp-json/salve/v1/user'
  const data = { form: type, ...user }
  const response = await context.dispatch('http/put', { url, data }, { root: true })

  if (response) {
    //  console.log("update user got response", response);
    context.commit('SET_USER', response.data)
  }
}
