import { dayjs } from '@/utils/date'

/*
 * Update a location's schedule with dayjs instances.
 */
export const computeLocationSchedule = (locationTimezone, locationSchedule) => {
  return locationSchedule.map((schedule) => {
    return {
      ...schedule,
      _computed: {
        date: createDateInstance(locationTimezone, schedule.day_of_week, '00:00'),
        order_by: createDateInstance(locationTimezone, schedule.day_of_week, schedule.order_by_time),
        pickup_start: createDateInstance(locationTimezone, schedule.day_of_week, schedule.from),
        pickup_end: createDateInstance(locationTimezone, schedule.day_of_week, schedule.to)
      }
    }
  })
}

/*
 * Convert a time string (ex. 20:00) to hours and minutes.
 */
const parseTime = (timeStr) => {
  const [hour, minute] = timeStr.split(':')
  return [parseInt(hour), parseInt(minute)]
}

/*
 * Create a dayjs instance representing the date/time for a location's
 * time variable. (ex. pickup by, order by, etc)
 */
export const createDateInstance = (timezone, dayOfWeek, timeStr) => {
  try {
    const [hour, minute] = parseTime(timeStr)
    return dayjs().tz(timezone).set('day', dayOfWeek).set('hour', hour).set('minute', minute)
  } catch {
    return null
  }
}

export const canPickupToday = (scheduleItem, now = dayjs()) => {
  try {
    return scheduleItem._computed.order_by.format('DD') === now.format('DD')
  } catch {
    return false
  }
}

export const canPickupTomorrow = (scheduleItem, now = dayjs()) => {
  try {
    return scheduleItem._computed.order_by.format('DD') === now.add(1, 'day').format('DD')
  } catch {
    return false
  }
}

/*
 * Find the soonest available pick-up day based on a location's schedule.
 */
export const nextPickupDay = (schedule, now = dayjs()) => {
  let firstDayWithPickup
  for (let i = 0; i < schedule.length; i++) {
    const day = schedule[i]
    if (day.has_pick_up) {
      if (day._computed.order_by && now.isBefore(day._computed.order_by)) return day
      if (!firstDayWithPickup) firstDayWithPickup = day
    }
  }

  if (firstDayWithPickup) {
    // We know the rollover day, but need to add 1 week to make the timestamps accurate.
    const rolloverDay = Object.assign({}, firstDayWithPickup)

    rolloverDay._computed = {
      date: firstDayWithPickup._computed.date.add(7, 'day'),
      order_by: firstDayWithPickup._computed.order_by.add(7, 'day'),
      pickup_start: firstDayWithPickup._computed.pickup_start.add(7, 'day'),
      pickup_end: firstDayWithPickup._computed.pickup_end.add(7, 'day')
    }

    return rolloverDay
  }
}

export const pickupLabel = (nextPickup, customerCanPickupToday, customerCanPickupTomorrow) => {
  if (customerCanPickupToday) {
    return `Same day pick-up cut-off time is ${nextPickup._computed.order_by.local().format('h:mmA')}.`
  }
  if (customerCanPickupTomorrow) {
    return `Order tomorrow before ${nextPickup._computed.order_by.local().format('h:mmA')} for same day pick-up.`
  }
  if (nextPickup) {
    return `Order on ${nextPickup._computed.order_by.local().format('dddd')} before ${nextPickup._computed.order_by
      .local()
      .format('h:mmA')} for same day pick-up.`
  }
  return ''
}

export const usePickupInfo = (schedule) => {
  const nextPickup = nextPickupDay(schedule)
  const customerCanPickupToday = nextPickup ? canPickupToday(nextPickup) : false
  const customerCanPickupTomorrow = nextPickup ? canPickupTomorrow(nextPickup) : false
  const customerPickupLabel = nextPickup
    ? pickupLabel(nextPickup, customerCanPickupToday, customerCanPickupTomorrow)
    : ''

  return {
    nextPickup,
    customerCanPickupToday,
    customerCanPickupTomorrow,
    customerPickupLabel
  }
}
