import Validator from 'validatorjs'
// @Note: https://github.com/mikeerickson/validatorjs/issues/454
import en from 'validatorjs/src/lang/en'
Validator.setMessages('en', en)

// Flowery password validation
Validator.register(
  'shop-password',
  function (value) {
    // check if string has a number
    if (!/\d/.test(value)) return false
    // check if string contains a capital letter
    return /[A-Z]/.test(value)
  },
  'The password must contain at least one capital letter and at least one number'
)

export default Validator
