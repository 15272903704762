/* eslint-disable */
import axios from 'axios'
import environment from '@/environment'

/**
 * Send a request to remote server
 * @param method get,post,put,delete, etc
 * @param url if no http:// then we attatch base url
 * @param data data variables to send with request
 * @param headers additional headers to add to request. state.commonHeaders will always be sent
 * @param auth if true authorization headers will be added to the request
 * @returns {AxiosPromise}
 */
function httpRequest(context, method = 'get', url = '', data = {}, headers = {}, auth = true) {
  const baseURL = environment.STORE_URL
  const theURL = baseURL + url

  // List of request headers to send
  let theHeaders = {
    ...context.state.commonHeaders,
    ...headers
  }

  // Add authorization header to request
  if (auth) {
    theHeaders = {
      ...theHeaders,
      ...context.getters.authorizationHeader
    }
  } else {
    // console.log("httpRequest: Skipping auth headers because auth=false");
  }

  if (process.server) {
    theHeaders = {
      ...theHeaders,
      ['Salve-Key']: environment.WC_TOKEN || 'TR031785NR032586JZ090790'
    }
  }

  return axios({
    method,
    data,
    url: theURL,
    headers: theHeaders
  })
}

export default {
  namespaced: true,

  // module assets
  state: () => ({
    // Headers we want to send on every request
    commonHeaders: { Accept: 'application/json' }
  }),

  getters: {
    /**
     * returns authorization header if user has logged into application
     */
    authorizationHeader(state, getters, rootState, rootGetters) {
      if (rootGetters['auth/xHasAccessToken']) {
        // console.log("authorizationHeader is authenticated", rootGetters['authuser/xAccessToken']);
        return { Authorization: 'Bearer ' + rootGetters['auth/xAccessToken'] }
      } else {
        // console.log("not authenticated", {...rootState.authuser});
      }
      return {}
    }
  },

  actions: {
    /**
     * Same as get but doesnt send auth token
     */
    noAuthGet(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'get', url, data, headers, false)
    },

    get(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'get', url, data, headers)
    },

    put(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'put', url, data, headers)
    },

    /**
     * Same as post but doesnt send auth token
     */
    noAuthPost(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'post', url, data, headers, false)
    },

    post(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'post', url, data, headers)
    },

    delete(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'delete', url, data, headers)
    },

    /**
     * Same as delete but doesnt send auth token
     */
    noAuthDelete(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'delete', url, data, headers, false)
    }
  },

  mutations: {
    /**
     * Add a header that will be sent with every request
     * @param state
     * @param headers list of key/val headers we wish to attach
     */
    addCommonHeader(state, headers) {
      state.commonHeaders = { ...state.commonHeaders, ...headers }
    }
  },

  // nested modules
  modules: {}
}
