export default {
  namespaced: true,
  state: () => ({}),
  getters: {
    /**
     * Returns a list of bank accounts that have been linked to aeropay
     */
    xLinkedBankAccounts(state, getters, rootState, rootGetters) {
      return rootGetters['authuser/xCurrentUser']?.payments || []
    },

    xDefaultBankAccountId(state, getters, rootState, rootGetters) {
      const preferences = rootGetters['authuser/xCurrentUserPreferences']
      const defaultPayment = preferences?.payment?.id
      if (!defaultPayment) {
        const accounts = getters.xLinkedBankAccounts
        return accounts?.[0]?.bank_account_id
      }
      return defaultPayment
    },

    /**
     * Function that accepts a bank account id and returns that bank account
     */
    xGetBankAccount(state, getters) {
      return (bankAccountID) => {
        return getters.xLinkedBankAccounts?.find((item) => item.bank_account_id === bankAccountID)
      }
    }
  }
}
