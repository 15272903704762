import { ENUM_END_POINT } from '@/utils/api/const'
import { ApiRequest } from '@/utils/api/api.request'
import { requestPipe } from '@/utils/api/api-pipes'

export class PageRequests extends ApiRequest {
  getLoyalty() {
    return this.get(`${ENUM_END_POINT.PAGES.GET_LOYALTY}`).then(requestPipe)
  }

  getAbout() {
    return this.get(`${ENUM_END_POINT.PAGES.GET_ABOUT}`).then(requestPipe)
  }

  getMedia() {
    return this.get(`${ENUM_END_POINT.PAGES.GET_MEDIA}`).then(requestPipe)
  }
}
