import { $axios } from './axios'

export class ApiRequest {
  get(...data) {
    return $axios.get(...data)
  }

  post(...data) {
    return $axios.post(...data)
  }

  put(...data) {
    return $axios.put(...data)
  }

  delete(...data) {
    return $axios.delete(...data)
  }
}
