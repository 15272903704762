import { ENUM_PLATFORM_ENDPOINT } from '@/utils/api/const'
import { ApiRequest } from '@/utils/api/api.request'
import { requestPipe } from '@/utils/api/api-pipes'

const config = {
  headers: {
    Authorization: 'Bearer 2|1CRoQlmMggvN74yDwi1YIXGDCo7jl5g3H5dgUqqX'
  }
}

export class RegionRequests extends ApiRequest {
  getRegion() {
    return this.get(`${ENUM_PLATFORM_ENDPOINT.REGION.GET_REGION}`, config).then(requestPipe)
  }
}
