import dayjs from 'dayjs'
import ToTwelveHour from './ToTwelveHour'
import environment from '@/environment'

/* eslint-disable */
/**
 * Returns an object that can calculate delivery dates
 * Each location.region we pull from the server has a schedules variable inside of it. This object parses this array
 * to determine valid and invalid delivery dates.
 *
 * Sample week data that might be parsed by this function. Note this is a guide only. The data might change whilst this
 * comment might not change.
 * [{
 * "day_of_week": 0,   // SUNDAY
 * "to": null,
 * "from": null,
 * "order_by_time": null,
 * "delivery_fee": null,
 * "min_amount": null,
 * "has_delivery": false, // NO DELIVERY TODAY
 * "is_active": false
 * }, {
 * "day_of_week": 1, // MONDAY
 * "to": "17:00",     // Delivery ends at 5PM
 * "from": "09:00",   // Delivery starts at 9 am
 * "order_by_time": null,  // Order must be received by
 * "delivery_fee": null,  // Delivery is free but
 * "min_amount": null,
 * "has_delivery": true,  // This is a valid delivery day
 * "is_active": true
 * },
 *    // REPEAT FOR TUES(2), WED(3), THURS(4), FRI(5), SAT(6)
 * ]
 *
 *
 * @param week A weekly schedule for a region. See note above on what this looks like
 * @param dateObj (optional) a date object loaded with a date/time to use for calculations. If null, we use the current
 *                date and the current time
 * @param day (optional) date library. Uses dayjs by default. Defaults to current day
 * @param toTwelveHour (optional) a function that converts 24 hour time strings to 12 hour
 * @returns object that can be used to determine delivery dates
 */
const Delivery = function (week = [], dateObj = null, day = dayjs, toTwelveHour = ToTwelveHour) {
  // If a date/time has been provided (dateObj variable) then use that for calculations. Otherwise generate one using
  // the day func
  let currentDate = day()
  if (dateObj) {
    // console.log('using dateObj', dateObj);
    currentDate = dateObj
  }

  // use today as the "current date" if supplied otherwise use the current day
  let currentDay = currentDate.day()

  // Week variable must provide the full weekly schedule (7 days)
  if (week.length !== 7) {
    throw new Error('Delivery was not provided full 7 day delivery schedule')
  }

  // console.error("DELIVERY LMAO", currentDate);

  /**
   * Returns a list of delivery days for the current week
   * @param locationId The location ID in Salve related to the region. This is used to determine the location's schedule if its different
   * @param numDays the number of days into the future we should look
   * @returns {*}
   */
  const findPossibleDeliveryDates = function findPossibleDeliveryDates(numDays, locationId, region = null) {
    const retval = []

    //console.log('delivery dates', numDays, locationId, region);

    // if Employee store then only 2 days delivery
    if (
      locationId === environment.STORE_CONFIG.FARM_EMPLOYEE_STORE_ID ||
      region == environment.STORE_CONFIG.HOMESTEAD_EMPLOYEE_STORE
    ) {
      numDays = 1
    }

    /**
     * Iterate through schedule looking for the next available delivery date
     */
    for (let i = currentDay; i <= currentDay + numDays; i++) {
      let ok = true

      // No same day delivery unless its an employee store
      if (
        i === currentDay &&
        (locationId !== environment.STORE_CONFIG.FARM_EMPLOYEE_STORE_ID ||
          region != environment.STORE_CONFIG.HOMESTEAD_EMPLOYEE_STORE)
      ) {
        ok = false
      } else {
        ok = true
      }

      // Next day delivery must be submitted before the order_by_time variable found in each enttry in week[] array
      if (
        locationId === environment.STORE_CONFIG.FARM_EMPLOYEE_STORE_ID ||
        region == environment.STORE_CONFIG.HOMESTEAD_EMPLOYEE_STORE
      ) {
        if (i === currentDay) {
          ok = orderedBeforeDailyCutoffTime(week[currentDay])
        }
      } else {
        if (i === currentDay + 1) {
          ok = orderedBeforeDailyCutoffTime(week[currentDay])
        }
      }

      if (ok) {
        // Constrains the iterator (i) to a value between 0-6. Example i=7 returns 0,  i=8 returns 1, i=14 returns 0
        const item = week[i % 7]
        // console.log("checking item", item.day_of_week, item);
        // Does this day have delivery service
        if (item.has_delivery) {
          retval.push(createDeliveryDate(i - currentDay, item))
        }
      }
    }

    return retval
  }

  /**
   * Returns the next available delivery day
   */
  const findNextDeliveryDate = function findNextDeliveryDate() {
    const possibleDeliveries = findPossibleDeliveryDates(20)
    // console.log("find next delivery got", possibleDeliveries);
    return possibleDeliveries.length > 0 ? possibleDeliveries[0] : null
  }

  function deliveryDay(day) {
    const retval = dayjs(day).format('dddd, MMMM DD, YYYY')
    return retval.toLowerCase() !== 'invalid date' ? retval : ''
  }

  /**
   * Formats a day of the week into an object that represents a possible delivery date.
   * @param date a day of week object. See constructor docblock for an example of the data that gets passed
   * @returns {{date, start, end}}
   */
  function createDeliveryDate(index, date) {
    // console.log("Create delivery date", index, date);
    // console.log({ ...week });

    const theDay = day(currentDate.toISOString()).add(index, 'd')
    return {
      date: theDay,
      start: toTwelveHour(date.from),
      end: toTwelveHour(date.to),
      orderBy: date.order_by_time,
      human: deliveryDay(theDay)
    }
  }

  /**
   * Returns true if the current time is less than the order_by_time in the provided day object
   * @param day a day object inside of the week[] array you provided in the constructor of this object
   */
  function orderedBeforeDailyCutoffTime(day) {
    // If there is no order by time specified just return true
    if (!day?.order_by_time) {
      return true
    }

    // Create a date object from the cutoff time, then subtract that date from the current date
    const dateStr = `${currentDate.format('YYYY-MM-D')}T${day.order_by_time}`
    const cutoffDate = dayjs(dateStr)
    const cutoffTime = cutoffDate.unix()
    const currentTime = currentDate.unix()

    // console.log("cutoff", cutoffDate.toString(), "current->", currentDate.toString(), dateStr, cutoffTime, currentTime, cutoffTime - currentTime);

    // If the cutoff time is less than the current time then we have passed the cutoff
    return !!(cutoffTime - currentTime > 0)
  }

  function nextDayDeliveryCutoff() {
    return week[currentDay]?.order_by_time || null
  }

  return {
    findPossibleDeliveryDates,
    findNextDeliveryDate,
    nextDayDeliveryCutoff
  }
}

export default Delivery
