import RoundTwo from '@/utils/filters/round-two'

/**
 * Formats number into a money string. 234.87 becomes "$234.87" - 100 becomes "$100.00" - 1234.56 becomes "$1,234.56"
 * @param num the number to format
 * @param locale "en" for englsh format ($1,234.56) - any other value for french format (1 234,56$)
 * @returns {string}
 */
export default function Money(num, locale = 'en') {
  let rounded = RoundTwo(num)
  if (locale === 'en' || locale === undefined) {
    return '$' + rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    rounded = rounded.toString().replace('.', ',')
    return rounded.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + '$'
  }
}
