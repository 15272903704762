import { ApiRequest } from '@/utils/api/api.request'
import { ENUM_END_POINT } from '@/utils/api/const'
import { requestPipe } from '@/utils/api/api-pipes'

export class CartRequests extends ApiRequest {
  addProduct(id, data) {
    return this.post(`${ENUM_END_POINT.CART.ADD_PRODUCT}${id}`, data).then(requestPipe)
  }

  getCart(id) {
    return this.get(`${ENUM_END_POINT.CART.GET_CART}?cart_key=${id}&html=true`).then(requestPipe)
  }

  clearCart() {
    return this.get(ENUM_END_POINT.CART.CLEAR_CART).then(requestPipe)
  }

  updateQty(id, data) {
    return this.post(`${ENUM_END_POINT.CART.UPDATE_QTY}/${id}?html=true`, data).then(requestPipe)
  }

  getCartItems() {
    return this.get(`${ENUM_END_POINT.CART.GET_CART_ITEMS}?html=true`).then(requestPipe)
  }

  getCartTotals() {
    return this.get(`${ENUM_END_POINT.CART.GET_CART_TOTALS}?html=true`).then(requestPipe)
  }

  getCartCount() {
    return this.get(`${ENUM_END_POINT.CART.GET_CART_COUNT}`).then(requestPipe)
  }

  removeItem(id) {
    return this.delete(`${ENUM_END_POINT.CART.REMOVE_ITEM}/${id}`).then(requestPipe)
  }
}
