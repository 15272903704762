/* eslint-disable */
import _ from 'lodash'
import UpdateUserData from './Utilities/UpdateUserData'
import DeleteAuthTokenFromLocalstorage from '@/store/auth/utilities/DeleteAuthTokenFromLocalstorage'

/**
 * This module handles the authenticated user state and server communication for authenticated user
 */
export default {
  namespaced: true,

  state: () => ({
    // the user account pulled from server
    user: null,

    // A place to store an address for an unauthenticated user
    temporaryAddress: null,

    temporaryPreferences: { purchase: {}, payment: {} },

    purchasePreferencesOverride: false
  }),

  getters: {
    /**
     * Returns the currently logged in user
     */
    xCurrentUser(state) {
      return state.user
    },

    xCurrentUserPreferences(state) {
      const preferences = state.user ? state.user?.preferences : state.temporaryPreferences

      if (state.purchasePreferencesOverride) {
        return Object.assign({}, preferences, { purchase: state.purchasePreferencesOverride })
      } else {
        return preferences
      }
    },

    xCurrentUserIsEmployee(state) {
      return state?.user?.is_employee
    },

    /**
     * Compares address against array of addresses in user object to determine if it's a a duplicate address
     * @param address
     * @return boolean returns true if address is a duplicate address
     */
    xIsDuplicateAddress(state, getters) {
      return (address) => {
        let retval = false
        // For each address compare against the supplied address
        Object.entries(getters.xGetAddressList).forEach((item) => {
          // Make a copy of the original address so we don't modify real data
          const copy = { ...item }

          // Remove address hash and "default address" boolean
          delete copy[1].address_id

          // Equvalent to (if array_diff in php)
          if (_.isEqual(copy[1], address)) {
            retval = true
          }
        })

        return retval
      }
    },

    /**
     * Returns true if the user has a temporary address added when they were unauthenticated
     */
    xHasTempAddress(state) {
      return state.temporaryAddress !== null
    },

    /**
     * Returns a list of addresses saved for the current user
     */
    xGetAddressList(state, getters) {
      /**
       * If a temporary address is listed in the state of this module, it means that the user provided an address when
       * he/she was unathenticated. Once an account is created or the user logs in, we make a request to the server to
       * add this temp address to the user's list of addresses and remove it from the temp var. This might cause
       * unexpected behaviour in places where the list of address is grabbed directly from user state so its
       * recommended that you always use this function to get addresses, and not user.addresses
       */
      // console.log("x get address list");
      if (state.temporaryAddress !== null) {
        return [state.temporaryAddress]
      }

      return getters?.xCurrentUser?.addresses || []
    },

    xUserHasAddress(state, getters) {
      return getters.xGetAddressList.length > 0
    },

    /**
     * Returns true if user is authenticated
     */
    xIsAuthenticated(state) {
      const ret = state.user !== null
      // console.log("is authenticated", ret);
      return ret
    }
  },

  actions: {
    /**
     * Fetches authenticated user object from the WP API
     */
    async xFetchAuthenticatedUser({ dispatch, commit }) {
      const url = '/wp-json/salve/v1/user'
      const data = {}

      // console.log("pulling auth user");
      const response = await dispatch('http/get', { url, data }, { root: true }).catch((response) => {
        console.error('could not fetch authenticated user', response)
        DeleteAuthTokenFromLocalstorage()
      })

      if (response) {
        if (response.data?.first_name && response.data?.email) {
          //console.log('IDENTIFYING USER', response.data)
          klaviyo
            .identify({
              email: response.data.email,
              first_name: response.data.first_name,
              last_name: response.data.last_name
            })
            .then(() => console.log('Identify has been completed'))

          // Get the user ID and set it as the external user ID for OneSignal
          let userId = response.data.email
          if (typeof nativeFunctions !== 'undefined') {
            nativeFunctions.onesignalSetExternalUserId(userId)
          }
        }

        commit('SET_USER', response.data)
        return response.data
      }
    },

    async xFetchAuthenticatedUserBankAccounts({ getters, dispatch, commit }) {
      const user = getters.xCurrentUser
      if (!user) return

      const url = '/wp-json/salve/v1/user/bank_accounts?user_id=' + user.user_id

      try {
        const response = await dispatch('http/get', { url }, { root: true })
        if (response) {
          commit('SET_USER_PAYMENTS', response.data)
        } else {
          throw new Error('No response from server')
        }
      } catch (e) {
        console.error(e.message)
        // Safest to clear the user's payment methods in the case of an error:
        commit('SET_USER_PAYMENTS', [])
      }
    },

    /**
     * Save a new address to the authenticated user
     * @param address the address we want to save
     */
    async xCreateNewAddress(context, address) {
      // console.log("xCreateNewAddress adding address", address);
      if (!address) {
        // console.error("xCreateNewAddress provided null address");
        return
      }

      // Make sure this isnt a duplicate
      if (context.getters.xIsDuplicateAddress(address)) {
        // console.error("Attempting to add duplicate address", address);
        return
      }

      // should add validation for address here
      const user = context.getters.xCurrentUser
      if (user) {
        address.is_new = true
        user.addresses.push(address)
        await UpdateUserData(context, { type: 'address', user })
      }
    },

    /**
     * Update an existing address to the authenticated user
     * @param address the address we want to update. Must have an address_id key
     */
    xUpdateAddress(context, address) {
      // console.log("xUpdate address", address);
      if (!address?.address_id) {
        // console.error("Provided address does not have an address_id key", address);
        return
      }

      context.commit('UPDATE_ADDRESS', address)

      // should add validation for address here
      const user = context.getters.xCurrentUser
      if (user) {
        const payload = { type: 'address', user }
        // console.log("xUpdateAdress sending", payload);
        UpdateUserData(context, { type: 'address', user })
      }
    },

    xUpdatePurchasePreferences(context, preferences) {
      context.commit('DELETE_PURCHASE_PREF_OVERRIDE')

      const user = context.getters.xCurrentUser

      // Eagerly commit the updated user preferences:
      context.commit('UPDATE_PURCHASE_PREFERENCES', preferences)

      if (!user) return
      // Sync/persist the preferences:
      UpdateUserData(context, { type: 'preferences', user })
    },

    xUpdatePaymentPreferences(context, preferences) {
      const user = context.getters.xCurrentUser

      // Eagerly commit the updated user preferences:
      context.commit('UPDATE_PAYMENT_PREFERENCES', preferences)

      if (!user) return
      // Sync/persist the preferences:
      UpdateUserData(context, { type: 'preferences', user })
    },

    /**
     * Adds apartment data to the currently selected address
     * @param apartment {string} A string containing the apartment data for the current user
     */
    async xAddApartmentToAddress({ state, getters, rootGetters, commit, dispatch }, apartment) {
      // console.log("xAddApartmentToAddress adding ", apartment);
      if (!apartment) {
        return
      }

      // If a temp address exists we add the apartment to that
      if (state.temporaryAddress) {
        // console.log("adding apartment to temp address");
        commit('ADD_APT_TEMP_ADDRESS', apartment)
        return
      }

      // console.log("no temp address, checking for a selected address");
      const selected = rootGetters['user-region/xSelectedAddress']
      if (selected && selected?.address_id) {
        const payload = { address_2: apartment, address_id: selected.address_id }
        // console.log("found selected address so calling update", selected, payload);
        await dispatch('xUpdateAddress', payload)
      }
    },

    /**
     * Sends a request to server to delete the provided address
     * @param addressToDelete the address to delete
     */
    async xDeleteAddress(context, addressToDelete) {
      if (context.getters.xIsAuthenticated) {
        const user = context.getters.xCurrentUser
        context.commit('SET_ADDRESS_DELETE_FLAG', addressToDelete.address_id)
        await UpdateUserData(context, { type: 'address', user })
      }
    },

    /**
     * Make a temporary address so an unauthenticated user can still buy things
     * @param address the address we want to save
     */
    xMakeTempAddress({ commit }, address) {
      // should add validation for address here
      commit('SET_TEMP_ADDRESS', address)
    },

    /**
     * Adds temporary addresses made when unauthenticated to the currently authenticated user
     */
    xAddTempAddressToAuthUser({ dispatch, getters, state, commit }) {
      // Remove the temp address from module state;
      const tempAddress = state.temporaryAddress
      commit('DELETE_TEMP_ADDRESS')

      // console.log("xAddTempAddressToAuthUser");
      if (!tempAddress) {
        // console.log("invalid temporary address");
        return
      }

      // Make sure this isnt a duplicate
      if (getters.xIsDuplicateAddress(tempAddress)) {
        // console.error("temp address already exists on user", tempAddress);
        return
      }

      // Send address to server to be saved asynchronously
      dispatch('xCreateNewAddress', tempAddress)

      // Add the address to the user then delete the temp address
      commit('ADD_ADDRESS', tempAddress)
    },

    /**
     * Sends a request to server to delete the provided payment type
     * @param payment the payment to delete
     */
    async xDeletePaymentType(context, payment) {
      // console.log("xDeletePaymentType deleting this payment", payment);
      if (context.getters.xIsAuthenticated) {
        const payload = {
          user: { ...context.getters.xCurrentUser },
          bank_account_id: payment.bank_account_id
        }
        const url = `/wp-json/salve/v1/payments/delete_bank?id=${payment.bank_account_id}`

        // console.log("callin url", url);
        const response = await context.dispatch('http/delete', { url, data: payload }, { root: true })
        // console.log("delete response is", response);

        if (response) {
          context.dispatch('xFetchAuthenticatedUser')
        }
      }
    },

    async xUpdateAccountDetails(context, user) {
      // await console.log("xUpdateAccountDetails", user);

      if (!context.getters.xIsAuthenticated) {
        // console.error("must be logged in to edit user data");
        return
      }

      await UpdateUserData(context, { type: 'account', user })
    },

    /**
     * Remove the auth user from internal state
     * @param commit
     */
    xRemoveAuthUserFromState({ commit }) {
      commit('DELETE_USER')
    }
  },

  mutations: {
    ADD_PURCHASE_PREF_OVERRIDE(state, { id, method = 'pickup' }) {
      state.purchasePreferencesOverride = { id, method }
    },
    DELETE_PURCHASE_PREF_OVERRIDE(state) {
      state.purchasePreferencesOverride = false
    },
    SET_ADDRESS_DELETE_FLAG(state, addressId) {
      const index = state.user.addresses.findIndex((address) => address.address_id === addressId)
      state.user.addresses[index].deleted = true
    },
    /**
     * Delete user from module state
     */
    DELETE_USER(state) {
      state.user = null
    },

    /**
     * Sets the authenticated user data pulled from the server
     * @param user the new user object
     */
    SET_USER(state, user) {
      state.user = user

      // console.log("WARING HARD CODING THE LIST OF USER ADDRESSS");
      // const addressList = [
      //   {"address_id":"1","address":"Miami Beach Boardwalk","address_2":"Miami-Dade County","city":"Miami Beach","state":"FL","zip":"33445","region":"","default_address":true,"location_id":"2"},
      //   {"address_id":"2","address":"Orlando somewhere","address_2":"mouse county","city":"orlando","state":"FL","zip":"32789","region":"","default_address":false,"location_id":"2"},
      //   {"address_id":"3","address":"NO DELIVERY","address_2":"","city":"sdsdf","state":"FL","zip":"32601","region":"","default_address":false,"location_id":"2"},
      //   {"address_id":"4","address":"should be northeast","address_2":"","city":"sdsdf","state":"FL","zip":"32034","region":"","default_address":false,"location_id":"2"},
      //   {"address_id":"5","address":"Jacksonville","address_2":"","city":"sdsdf","state":"FL","zip":"32202","region":"","default_address":false,"location_id":"2"},
      // ];
      //
      // state.user.addresses = addressList;
    },

    /*
      Update the user's payment methods with latest data.
      @param paymentData the new payment data.
     */
    SET_USER_PAYMENTS(state, paymentData) {
      state.user.payments = paymentData
    },

    /**
     * Adds a new address to user object
     * @param address the new address object
     */
    ADD_ADDRESS(state, address) {
      // console.log("ADD_ADDRESS MUTATION", address);
      state?.user?.addresses?.push(address)
    },

    /**
     * Update an existing address on user object
     * @param address the new address object
     */
    UPDATE_ADDRESS(state, address) {
      // console.log("UPDATE_ADDRESS MUTATION", address);

      // A list of fields that can be updated
      const whiteList = ['address', 'address_2', 'city', 'state', 'zip']

      // Provided address must have address_id key
      if (!address?.address_id) {
        // console.error("UPDATE_ADDRESS: provided address does not have an address_id key");
        return
      }

      // Find the address in the list of user addresses and if found update the keys with the provided address
      const foundInList = state?.user?.addresses?.find((item) => item.address_id === address.address_id)

      if (foundInList) {
        // console.log("found address");

        /**
         * Swap the fields in the user's address with those found in the provided address object. We only swap fields
         * specified in the white list
         */
        Object.values(whiteList).forEach((key) => {
          // console.log("looking for", key);
          if (Object.prototype.hasOwnProperty.call(address, key)) {
            // console.log("foundInList has", key);
            foundInList[key] = address[key]
          }
        })
      } else {
        // console.log("no address with id");
      }
    },

    /**
     * Add a temporary address for an unauthenticated user
     * @param address the address object
     */
    SET_TEMP_ADDRESS(state, address) {
      const addr = { ...address, address_id: 'temp' }
      state.temporaryAddress = addr

      // console.log("added temp address", state, addr);
    },

    /**
     * Adds an apartment number to a temp address
     * @param apt
     */
    ADD_APT_TEMP_ADDRESS(state, apt) {
      if (state.temporaryAddress) {
        state.temporaryAddress.address_2 = apt
      }
    },

    /**
     * Deletes the temporary address stored in module state
     */
    DELETE_TEMP_ADDRESS(state) {
      // console.log("REMOVING TEMP ADDRESS");
      state.temporaryAddress = null
    },

    UPDATE_PURCHASE_PREFERENCES(state, purchasePreferences) {
      if (state.user) {
        state.user.preferences.purchase = purchasePreferences
      } else {
        state.temporaryPreferences.purchase = purchasePreferences
      }
    },

    UPDATE_PAYMENT_PREFERENCES(state, paymentPreferences) {
      if (state.user) {
        state.user.preferences.payment = paymentPreferences
      } else {
        state.temporaryPreferences.payment = paymentPreferences
      }
    }
  },

  // nested modules
  modules: {}
}
