export default defineNuxtPlugin((nuxtApp) => {
  useHead({
    script: [
      // Other scripts
      {
        src: 'https://aggle.net/js?publisher=herb.co&pid=DRVICTVF',
        type: 'text/javascript'
      },
      {
        src: 'https://tags.herb.co?appId=10f65dd0-35e0-4664-ac2b-2ff2e4277aa7&environment=thirdparty&segmentId=VeGIfiJZsNmZ2l76u3hXSA&version=2',
        type: 'text/javascript'
      }
    ]
  })
})
