/*
  Custom plugin to bootstrap vue-gtag
 */
import { defineNuxtPlugin } from '#imports'
import VueGtag from 'vue-gtag'
import environment from '@/environment'

export default defineNuxtPlugin((nuxtApp) => {
  const token = environment?.GOOGLE_TOKEN
  if (!token) return

  const options = {
    config: {
      id: token
    }
  }

  // The app name (required to enable screen view)
  const appName = environment?.GOOGLE_APP_NAME

  if (appName) {
    options.config.pageTrackerScreenviewEnabled = true
    options.config.appName = appName
  }

  nuxtApp.vueApp.use(VueGtag, options, nuxtApp.$router)
})
