/* eslint-disable */
import axios from 'axios'
import environment from '@/environment'

/**
 * Send a request to remote server
 * @param method get,post,put,delete, etc
 * @param url if no http:// then we attatch base url
 * @param data data variables to send with request
 * @param headers additional headers to add to request. state.commonHeaders will always be sent
 * @returns {() => AxiosPromise, () => void}
 */
function httpRequest(context, method = 'get', url = '', data = {}, headers = {}) {
  const baseURL = environment.PLATFORM_URL
  const theURL = baseURL + url

  // List of request headers to send
  const theHeaders = {
    ...context.getters.authorizationHeader,
    ...context.state.commonHeaders,
    ...headers
  }

  const controller = new AbortController()

  const request = () => {
    return axios({
      method,
      data,
      url: theURL,
      headers: theHeaders,
      signal: controller.signal
    })
  }

  const cancelRequest = () => {
    return controller.abort()
  }

  return {
    request,
    cancelRequest
  }
}

export default {
  namespaced: true,

  // module assets
  state: () => ({
    // Headers we want to send on every request
    commonHeaders: { Accept: 'application/json' },

    // The base url of our API server. should not have a trailing slash
    baseurl: ''
  }),

  getters: {
    /**
     * returns authorization header if user has logged into application
     */
    authorizationHeader(state, getters, rootState, rootGetters) {
      // hard coded auth token we can use for now
      return { Authorization: 'Bearer' + ' ' + environment.SALVE_TOKEN }
    }
  },

  actions: {
    get(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'get', url, data, headers)
    },

    post(context, { url = '', data = {}, headers = {} }) {
      return httpRequest(context, 'post', url, data, headers)
    }
  },

  mutations: {
    /**
     * Add a header that will be sent with every request
     * @param state
     * @param headers list of key/val headers we wish to attach
     */
    addCommonHeader(state, headers) {
      state.commonHeaders = { ...state.commonHeaders, ...headers }
    }
  },

  // nested modules
  modules: {}
}
