export const ErrorInterceptor = (error) => {
  error = error.response

  if (!error) {
    return Promise.reject(error)
  }

  if (error.data.code === 'jwt_auth_invalid_token' && process.client) {
    localStorage.removeItem('token')
    window.$nuxt.$router.push('/')
    window.$nuxt.$store.commit('setToken', null)
    // console.log(process.client, '______ERROR______', error)
  }

  return Promise.reject(error)
}
