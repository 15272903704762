import { ENUM_END_POINT } from '@/utils/api/const'
import { ApiRequest } from '@/utils/api/api.request'
import { requestPipe } from '@/utils/api/api-pipes'

export class BlogRequests extends ApiRequest {
  getBlog() {
    return this.get(`${ENUM_END_POINT.BLOG.GET_BLOG}`).then(requestPipe)
  }

  getArticle(slug) {
    return this.get(`${ENUM_END_POINT.BLOG.GET_ARTICLE}${slug}`).then(requestPipe)
  }

  getPost(id) {
    return this.get(`${ENUM_END_POINT.BLOG.GET_POST}?id=${id}`).then(requestPipe)
  }

  getSinglePost(id) {
    return this.get(`${ENUM_END_POINT.BLOG.GET_POST}/${id}`).then(requestPipe)
  }

  getTopPosts() {
    return this.get(`${ENUM_END_POINT.BLOG.GET_POSTS}?per_page=2`).then(requestPipe)
  }

  getRandomPosts() {
    return this.get(`${ENUM_END_POINT.BLOG.GET_RANDOM}?per_page=2`).then(requestPipe)
  }

  getPosts() {
    return this.get(`${ENUM_END_POINT.BLOG.GET_POSTS}`).then(requestPipe)
  }
}
