import KeyFromLocal from './GetCartKeyFromLocalstorage'

/**
 * Returns the cart key we can use to get a new cart
 * @param context { commit, dispatch, rootGetters, state } Destructured vuex context variable
 * @param user   optional user object that we want a specific cart key for
 * @param loader the function that returns cart_key from localstorage. Defaults to the GetCartKeyFromLocalStorage func
 *               found in the file of the same name but can be overrriden for testing or whatever else
 * @param forceNewKey if true, forces the generation of a new cart key
 * @untested
 */
async function FindCartKey(
  { commit, dispatch, rootGetters, state },
  user = null,
  loader = KeyFromLocal,
  forceNewKey = false
) {
  let cartKey = null

  if (forceNewKey) {
    // Force fetching a new key from the server
    if (process.server) {
      throw new Error('Cart is inaccessible on the server.')
    }

    let url = '/wp-json/salve/v1/get_cart_key/?force_new=true'

    if (user) {
      url += '&userId=' + user.user_id
    }

    console.log('Forcing new cart key from server', url)
    const response = await dispatch('http/noAuthGet', { url, data: {} }, { root: true })
    cartKey = response.data
  } else {
    // Existing logic to find or generate a key
    if (state.cartKey) {
      cartKey = state.cartKey
    }

    if (!cartKey) {
      cartKey = loader()
    }

    if (!cartKey) {
      if (process.server) {
        throw new Error('Cart is inaccessible on the server.')
      }

      let url = '/wp-json/salve/v1/get_cart_key/'

      if (user) {
        url += '?userId=' + user.user_id
      }

      console.log('fetching cart key from server', url)
      const response = await dispatch('http/noAuthGet', { url, data: {} }, { root: true })
      cartKey = response.data
    }
  }

  // Save cart key in module state and localstorage
  commit('SET_CART_KEY', cartKey)
  commit('LOCALSTORAGE_SET_CART_KEY', cartKey)

  console.log('FindCartKey Returning key', cartKey)
  return cartKey
}

export default FindCartKey
