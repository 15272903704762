export default {
  namespaced: true,

  state: () => ({
    lastOrders: {}
  }),

  getters: {
    /**
     * Returns a list of orders for the provided page. IF no orders for that page have been fetched returns []
     */
    xCompletedOrders(state) {
      return (page = 1) => state?.lastOrders?.[page] || null
    }
  },

  actions: {
    async xFetchCompletedOrders(context, { page = 1, limit = 10 }) {
      const url = `/wp-json/salve/v1/user/orders?page=${page}&limit=${limit}&order=DESC&order_by=date`
      const data = {}
      // const data = {page, limit: 20, order: "DESC", order_by: "date"};
      console.log('xFetchCompletedOrders', page, url)

      try {
        const response = await context.dispatch('http/get', { url, data }, { root: true })
        console.log('xFetchCompletedOrders received response', response.data)
        context.commit('ORDER_PAGE', { page, result: response.data })
      } catch (e) {
        console.warn('something happened to xFetchCompletedOrders', e)
      }
    }
  },

  mutations: {
    /**
     * Save fetched orders to module state
     */
    ORDER_PAGE(state, { page, result }) {
      console.log('ORDER_PAGE MUTATION', page, result)
      state.lastOrders[page] = {
        orders: result.data,
        pagination: {
          current_page: page,
          last_page: result.last_page,
          post_count_on_page: result.post_count_on_page,
          total: result.total
        }
      }

      state.lastOrders = { ...state.lastOrders }
    }
  }
}
