export default defineNuxtPlugin((nuxtApp) => {
  const correctedImageUrl = (url, sizes, modifiers) => {
    if (typeof url === 'boolean') {
      // console.log('-------> Recieved boolean for url')
      return { cleanUrl: '/', srcSet: '/' }
    }

    // console.log('-------> url', url)

    sizes = sizes || false
    const baseUrl = 'https://ik.imagekit.io/salve'
    const pattern = 'https://cdn.theflowery.co'
    let cleanUrl = url
    let srcsetUrl = url

    if (url.includes(pattern)) {
      cleanUrl = url.replace(pattern, '')
    }

    srcsetUrl = baseUrl + cleanUrl

    let modifier = ''
    let srcSet = {}

    if (sizes) {
      srcSet = Object.values(sizes)
        .map((width) => {
          let modifiersString = ''
          if (modifiers) {
            for (const [key, value] of Object.entries(modifiers)) {
              modifiersString += `${key}-${value},`
            }
          }
          if (modifiersString) {
            modifier = `tr=w-${width},${modifiersString}`
          } else {
            modifier = `tr=w-${width}`
          }
          return `${srcsetUrl}?${modifier.trimEnd(',')} ${width}w`
        })
        .join(',\n')
    } else {
      if (modifiers) {
        let modifiersString = ''
        for (const [key, value] of Object.entries(modifiers)) {
          modifiersString += `${key}-${value},`
        }
        if (modifiersString) {
          modifier = `tr=${modifiersString}`
        }
      }
      if (modifier) {
        cleanUrl = `${cleanUrl}?${modifier.trimEnd(',')}`
      }
    }
    return { cleanUrl, srcSet }
  }

  // Inject the function into the Nuxt app so it can be used globally
  nuxtApp.provide('correctedImageUrl', correctedImageUrl)
})
