/**
 * This module handles communication with berbix
 */

export default {
  namespaced: true,
  name: 'BerbixVerify',

  state: () => ({
    client_token: null
  }),

  getters: {
    /**
     * Returns list of doctors
     */
    xBerbixData(state) {
      return state
    }
  },

  actions: {
    async xCreateBerbixTransaction(context) {
      // console.log("xCreateBerbixTransaction called");
      const url = `/wp-json/salve/v1/user/create_transaction`

      try {
        const response = await context.dispatch('http/get', { url, data: {} }, { root: true })
        // console.log("xCreateBerbixTransaction received response", response);

        if (response?.data) {
          context.commit('CREATE_BERBIX_TRANSACTION', response.data)
          return response.data
        }
      } catch (e) {
        // console.warn("something happened to xCreateBerbixTransaction", e);
      }
    },

    async xFetchCompletedBerbixTransaction(context, token) {
      // console.log("xFetchCompletedBerbixTransaction called", token);
      const url = `/wp-json/salve/v1/user/fetch_transaction`

      try {
        const response = await context.dispatch('http/post', { url, data: { clientToken: token } }, { root: true })
        // console.log("xFetchCompletedBerbixTransaction received response", response);

        return response?.data
      } catch (e) {
        // console.warn("something happened to xFetchCompletedBerbixTransaction", e);
      }
    }
  },

  mutations: {
    /**
     * Set Berbix transaction values
     * @param state
     * @param berbix
     * @constructor
     */
    CREATE_BERBIX_TRANSACTION(state, berbix) {
      Object.keys(state).forEach((key) => {
        if (berbix?.[key]) {
          // console.log("berbix setting ", key);
          state[key] = berbix[key]
        }
      })

      if (berbix?.response?.user_id) {
        state.user_id = berbix.response.user_id
      }
    }
  }
}
