import { createStore } from 'vuex'
import headerStorage from '@/store/header'
import tokenStorage from './token'
import userStorage from './user'
import homeStorge from './home'
import regions from '@/store/region/region'
import UserRegion from '@/store/user-region/user-region'
import auth from '@/store/auth/auth'
import authuser from '@/store/authuser/authuser'
import http from '@/store/core_modules/http/http'
import platformhttp from '@/store/core_modules/http/platformHttp'
import aeropay from '@/store/aeropay/aeropay'
import cart from '@/store/cart/cart'
import fireworks from '@/store/fireworks/fireworks'
import product from '@/store/product/product'
import berbix from '@/store/berbix/berbix'
import pageutil from '@/store/pageutil/pageutil'
import blog from '@/store/blog/blog'
import profile from '@/store/profile/profile'
import notifications from '@/store/notifications/notifications'
import shop from '@/store/shop/shop'
import modals from '@/store/modals'

export const newStore = () => {
  return createStore({
    modules: {
      home: homeStorge,
      token: tokenStorage,
      user: userStorage,
      header: headerStorage,
      shop,
      http,
      platformhttp,
      regions,
      'user-region': UserRegion,
      auth,
      authuser,
      cart,
      notifications,
      pageutil,
      blog,
      fireworks,
      profile,
      berbix,
      aeropay,
      product,
      modals
    }
  })
}
