import homeFields from '@/utils/fields/home-fields'

export const homePipe = (response) => {
  const homePage = response.shift()
  response = {}

  if (homePage?.yoast_meta) {
    response.yoast_meta = homePage.yoast_meta
  }

  if (homePage?.yoast_head_json?.title) {
    response.yoast_title = homePage?.yoast_head_json?.title
  }

  for (const [fieldName, realFieldName] of Object.entries(homeFields)) {
    if (homePage.acf[realFieldName] !== undefined) {
      response[fieldName] = homePage.acf[realFieldName]
    }
  }

  return response
}
