// utils/schema.js

import { useState } from '#app'

export const createWebsiteSchema = () => {
  const storeName = useState('storeName')
  const storeUrl = useState('storeUrl')
  const storeDescription = useState('storeDescription')

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': `${storeUrl.value}/#website`,
    url: storeUrl.value,
    name: storeName.value,
    description: storeDescription.value,
    publisher: {
      '@id': `${storeUrl.value}/#organization`
    }
  }
}

export const createSchemaScript = (schema) => ({
  type: 'application/ld+json',
  children: typeof schema === 'string' ? schema : JSON.stringify(schema)
})
